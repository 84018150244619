import { Component, OnInit } from '@angular/core';
import {ProjectService} from '../services/project.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {MainMapService} from '../services/main-map.service';
import {LoadingService} from '../services/loading.service';
import {EditService} from '../services/edit.service';
import {finalize, flatMap, switchMap} from 'rxjs/operators';
import {CommentService} from '../services/comment.service';
import {CommentPopUpComponent} from '../comment-pop-up/comment-pop-up.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {
  displayColumns = ['Comments']
  project_id: string;

  constructor(public projectService: ProjectService, private route: ActivatedRoute, private router: Router,
              public mapService: MainMapService, public editService: EditService, public  loadingService: LoadingService,
              public dialog: MatDialog, public commentService: CommentService) {
  }


  ngOnInit() {
    this.loadingService.show();
    this.route.parent.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.project_id = params.get('id');
        return this.commentService.layerIsLoaded.pipe(
          switchMap(() => {
            this.commentService.filter.where = `ProjectFK = '${this.project_id}'`;
            return this.commentService.getItems().pipe(finalize(() => this.loadingService.hide()));
          })
        );
      })).subscribe();
  }
  openDialog(comment): void {
    if (comment.attributes.GlobalID === 'new') {
      this.commentService.create_new_comment(this.project_id).subscribe(new_comment => {
        this.dialog.open(CommentPopUpComponent, {
          width: '650px',
          data: {comment: new_comment}
        }).afterClosed().pipe(flatMap(result => {
          console.log('The dialog was closed');
          return this.commentService.getItems();
        })).subscribe();
      });
    } else {
      this.dialog.open(CommentPopUpComponent, {
        width: '650px',
        data: {comment: comment}
      });
    }
  }
}
