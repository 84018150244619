import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IdentityManagementService} from './services/identity-management.service';
import {finalize} from 'rxjs/operators';
import { DataService } from './services/data.service';
import {environment} from '../environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  message: string;
  logo = environment.Logo;
  constructor(private activatedRoute: ActivatedRoute, private identityManager: IdentityManagementService, private data: DataService) {
    // const oauth = {access_token: '', username: '', expires_in: ''};
    // activatedRoute.fragment.subscribe(fragment => {
    //   if (fragment && fragment.indexOf('access_token') > -1) {
    //     fragment.split('&').forEach(frag => {
    //       const f = frag.split('=');
    //       oauth[f[0]] = f[1];
    //     });
    //   }
    // });
  }
}
