import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ProjectService} from '../services/project.service';
import {FieldworkService} from '../services/fieldwork.service';
import {LoadingService} from '../services/loading.service';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-fieldwork-pop-up',
  templateUrl: './fieldwork-pop-up.component.html',
  styleUrls: ['./fieldwork-pop-up.component.css']
})
export class FieldworkPopUpComponent implements OnInit {
  project: Subject<any>;
  featureForm = new FormGroup({
    ProjectID: new FormControl(),
    Project_Number: new FormControl(),
    Fieldwork_Type: new FormControl(),
    Acres_Surveyed: new FormControl(),
    Probes_Units: new FormControl(),
    Num_Pre_Sites: new FormControl(),
    Num_Hist_Sites: new FormControl(),
    Num_Multi_Sites: new FormControl(),
    Num_Isolates: new FormControl(),
    Cost: new FormControl(),
    Contract: new FormControl(),
    Start_Date: new FormControl(),
    Comments: new FormControl(),
    GlobalID: new FormControl(),
    ProjectFK: new FormControl(),
    OBJECTID: new FormControl()
  });
  constructor(public dialogRef: MatDialogRef<FieldworkPopUpComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public fieldworkService: FieldworkService, public projectService: ProjectService, public  loadingService: LoadingService) {}

  ngOnInit() {
    // this.loadingService.show();
    this.featureForm.patchValue(this.data.fieldwork.attributes);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  save() {
     this.data.fieldwork.attributes = this.featureForm.value;
     if (this.data.fieldwork.attributes.GlobalID === 'new') this.fieldworkService.addFeature(this.data.fieldwork).subscribe(() => this.dialogRef.close());
     else this.fieldworkService.updateFeature(this.data.fieldwork).subscribe(() => this.dialogRef.close());
   }
}
