import {Component, OnInit} from '@angular/core';
import {ArcPrintingService} from '../services/arc-printing.service';
import {ArcBaseService} from '../services/arc-base.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {finalize} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import {ProjectService} from '../services/project.service';
import {LandOwnershipLU, LandOwnershipLuService} from '../services/land-ownership-lu.service';
import {ReviewService} from '../services/review.service';
import {ReviewResponseLU, ReviewResponseLuService} from '../services/review-response-lu.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  project_in_review_service;
  project_in_review_loading = false;

  // hpf_grants_service;
  // hpf_grants_loading = false;

  fieldwork_summary_service;
  fieldwork_summary_loading = false;

  summary_by_field_service;
  summary_by_field_loading = false;

  featureForm = new FormGroup({
    // f: new FormControl('json'),
    project_name: new FormControl(),
    project_status: new FormControl(),
    DateStart: new FormControl(),
    DateEnd: new FormControl()
  });

  featureForm_sf = new FormGroup({
    DateStart: new FormControl(),
    DateEnd: new FormControl(),
    Proponent: new FormControl(),
    Section_106: new FormControl(),
    Lead_Agency: new FormControl(),
    Other_Agency: new FormControl(),
    Category: new FormControl(),
    Ancestral_Territory: new FormControl(),
    Review_Response: new FormControl(),
    Land_Ownership: new FormControl(),
    Notification_Method: new FormControl(),
    Response_Method: new FormControl(),
  });

  featureForm_fs = new FormGroup({
    // f: new FormControl('json'),
    project_name: new FormControl(),
    project_status: new FormControl(),
    DateStart: new FormControl(),
    DateEnd: new FormControl()
  });


  constructor(snackBar: MatSnackBar,
              public projectService: ProjectService,
              public landOwnershipLuService: LandOwnershipLuService,
              public reviewService: ReviewService,
              public reviewResponseLuService: ReviewResponseLuService) {
    this.project_in_review_service = new ArcPrintingService('ProjectsInReview/GPServer/ProjectsInReview', snackBar);
    // this.hpf_grants_service = new ArcPrintingService('HPFGrant/GPServer/HPFGrant', snackBar);
    this.summary_by_field_service = new ArcPrintingService('SummaryByField/GPServer/SummaryByField', snackBar);
    this.fieldwork_summary_service = new ArcPrintingService('FieldworkSummary/GPServer/FieldworkSummary', snackBar);

  }

  ngOnInit() {
  }

  printProjectsInReview() {
    this.project_in_review_loading = true;
    const vm = this;
    const params = {
      linkBack: document.location.origin
    };

    vm.project_in_review_service.submit(params).pipe(
      finalize(() => this.project_in_review_loading = false)
    ).subscribe();
  }

  // printHPFGrants() {
  //   this.hpf_grants_loading = true;
  //   const vm = this;
  //     const params = {
  //       // 'f': 'json',
  //
  //       DateStart: vm.featureForm.value.DateStart ? this.createDateAsUTC(vm.featureForm.value.DateStart) : null,
  //       DateEnd: vm.featureForm.value.DateEnd ? this.createDateAsUTC(vm.featureForm.value.DateEnd) : null,
  //     };
  //   vm.hpf_grants_service.submit(params).pipe(
  //     finalize(() => this.hpf_grants_loading = false)
  //   ).subscribe();
  // }

  summaryByField() {
    this.summary_by_field_loading = true;
    const vm = this;
    const params = {
      DateStart: vm.featureForm_sf.value.DateStart ? this.createDateAsUTC(vm.featureForm_sf.value.DateStart) : null,
      DateEnd: vm.featureForm_sf.value.DateEnd ? this.createDateAsUTC(vm.featureForm_sf.value.DateEnd) : null,
      Proponent: vm.featureForm_sf.value.Proponent,
      Section106: vm.featureForm_sf.value.Section_106,
      LeadAgency: vm.featureForm_sf.value.Lead_Agency,
      OtherAgency: vm.featureForm_sf.value.Other_Agency,
      Category: vm.featureForm_sf.value.Category, // single select
      AncestralTerritory: vm.featureForm_sf.value.Ancestral_Territory,
      ReviewResponse: vm.featureForm_sf.value.Review_Response,
      LandOwnership: vm.featureForm_sf.value.Land_Ownership, // multi select
      NotificationMethod: vm.featureForm_sf.value.Notification_Method, // single select
      ResponseMethod: vm.featureForm_sf.value.Response_Method,

      linkBack: document.location.origin
    };
    vm.summary_by_field_service.submit(params).pipe(
      finalize(() => this.summary_by_field_loading = false)
    ).subscribe();
  }

  createDateAsUTC(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(),
      date.getMinutes(), date.getSeconds()));
  }

  convertDateToUTC(date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(),
      date.getUTCMinutes(), date.getUTCSeconds());
  }


  printFieldworkSummary() {
    this.fieldwork_summary_loading = true;
    const vm = this;
    const params = {
      DateStart: vm.featureForm_fs.value.DateStart ? this.createDateAsUTC(vm.featureForm_fs.value.DateStart) : null,
      DateEnd: vm.featureForm_fs.value.DateEnd ? this.createDateAsUTC(vm.featureForm_fs.value.DateEnd) : null,
      linkBack: document.location.origin

    };
    vm.fieldwork_summary_service.submit(params).pipe(
      finalize(() => this.fieldwork_summary_loading = false)
    ).subscribe();
  }
}
