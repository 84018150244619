import {Component, Inject, OnInit} from '@angular/core';
import {CulturalPopUpComponent} from '../cultural-pop-up/cultural-pop-up.component';
import {ProjectService} from '../services/project.service';
import {LoadingService} from '../services/loading.service';
import {ShpoService} from '../services/shpo.service';
import {TcpService} from '../services/tcp.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {T} from '@angular/core/src/render3';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-tcp-pop-up',
  templateUrl: './tcp-pop-up.component.html',
  styleUrls: ['./tcp-pop-up.component.css']
})
export class TcpPopUpComponent implements OnInit {
  project: Subject<any>;
  featureForm = new FormGroup({
    TCP_in_APE: new FormControl(),
    TCP_Effect: new FormControl(),
    Comments: new FormControl(),
    ProjectFK: new FormControl(),
    GlobalID: new FormControl(),
    ObjectID: new FormControl()
  });

  constructor(public dialogRef: MatDialogRef<TcpPopUpComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public tcpService: TcpService, public projectService: ProjectService, public  loadingService: LoadingService) {}


  ngOnInit() {
    this.featureForm.patchValue(this.data.tcp.attributes);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  save() {
    this.data.tcp.attributes = this.featureForm.value;
    if (this.data.tcp.attributes.GlobalID === 'new') this.tcpService.addFeature(this.data.tcp).subscribe(() => this.dialogRef.close());
    else this.tcpService.updateFeature(this.data.tcp).subscribe(() => this.dialogRef.close());
  }
}
