import {Component, OnInit} from '@angular/core';
import {MainMapService} from '../services/main-map.service';
import {LoadingService} from '../services/loading.service';
import {TaskService} from '../services/task.service';
import {MatDialog} from '@angular/material/dialog';
import {EditService} from '../services/edit.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ProjectService} from '../services/project.service';
import {FieldworkService} from '../services/fieldwork.service';
import {TaskPopUpComponent} from '../task-pop-up/task-pop-up.component';
import {FieldworkPopUpComponent} from '../fieldwork-pop-up/fieldwork-pop-up.component';
import {switchMap, tap, finalize, flatMap} from 'rxjs/operators';

@Component({
  selector: 'app-fieldwork',
  templateUrl: './fieldwork.component.html',
  styleUrls: ['./fieldwork.component.css']
})
export class FieldworkComponent implements OnInit {
  displayColumns = ['Fieldwork_Type', 'Start_Date', 'Acres_Surveyed', 'Num_Pre_Sites', 'Num_Hist_Sites', 'Num_Multi_Sites'];
  project_id: string;

  constructor(public projectService: ProjectService, private route: ActivatedRoute, private router: Router,
              public mapService: MainMapService, public editService: EditService, public  loadingService: LoadingService,
              public fieldworkService: FieldworkService, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.loadingService.show();
    this.route.parent.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.project_id = params.get('id');
        return this.fieldworkService.layerIsLoaded.pipe(
          switchMap(() => {
            this.fieldworkService.filter.where = `ProjectFK = '${this.project_id}'`;
            return this.fieldworkService.getItems().pipe(finalize(() => this.loadingService.hide()));
          })
        );
      })).subscribe();
  }

  openDialog(fieldwork): void {
    if (fieldwork.attributes.GlobalID === 'new') {
      this.fieldworkService.create_new_fieldwork(this.project_id).subscribe(new_fieldwork => {
        this.dialog.open(FieldworkPopUpComponent, {
          width: '650px',
          data: {fieldwork: new_fieldwork}
        }).afterClosed().pipe(flatMap(result => {
          console.log('The dialog was closed');
          return this.fieldworkService.getItems();
        })).subscribe();
      });
    } else {
      this.dialog.open(FieldworkPopUpComponent, {
        width: '650px',
        data: {fieldwork: fieldwork}
      });
    }
  }
}
