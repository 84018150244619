<h1 mat-dialog-title>Cultural Resource
</h1>
<div mat-dialog-content>
  <form novalidate [formGroup]="featureForm">
    <div style="display: flex; flex-direction: column; justify-content: space-between;">
      <mat-form-field>
        <mat-placeholder>{{shpoService.meta.Project_Number.alias}}</mat-placeholder>
        <input matInput type="text" name="Project Number" formControlName="Project_Number">
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="save()" cdkFocusInitial>Save</button>
</div>
