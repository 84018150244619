<mat-card>
  <button mat-raised-button color="accent" (click)="printProjectsInReview()" [disabled]="project_in_review_loading"
          matTooltip="Run Report">
    Projects in Review
    <mat-progress-spinner [diameter]="30" color="primary" mode="indeterminate" *ngIf="project_in_review_loading"
                          style="position: absolute; top:3px; left: 35%"></mat-progress-spinner>
  </button>
  <div style="display: flex; flex-direction: column">
    <!--<form novalidate [formGroup]="featureForm">-->
    <!--<mat-form-field>-->
    <!--<mat-placeholder>Project Status</mat-placeholder>-->
    <!--<mat-select formControlName="project_status">-->
    <!--<mat-option *ngFor="let option of projectService.meta.Review_Status.domain.codedValues"-->
    <!--[value]="option.code">{{ option.name }}-->
    <!--</mat-option>-->
    <!--</mat-select>-->
    <!--</mat-form-field>-->
    <!--</form>-->
  </div>
</mat-card>
<!--<mat-card>-->
<!--  <button mat-raised-button color="accent" (click)="printHPFGrants()" [disabled]="hpf_grants_loading">-->
<!--    HPF Grants-->
<!--    <mat-progress-spinner [diameter]="30" color="primary" mode="indeterminate" *ngIf="hpf_grants_loading"-->
<!--                          style="position: absolute; top:3px; left: 35%"></mat-progress-spinner>-->
<!--  </button>-->
<!--  <div style="display: flex; flex-direction: column" *ngIf="projectService.layerIsLoaded | async">-->
<!--    <form novalidate [formGroup]="featureForm">-->
<!--      <mat-form-field>-->
<!--        <mat-placeholder>Start Date</mat-placeholder>-->
<!--        <input matInput [matDatepicker]="DateStartPicker" name="DateStart"-->
<!--               formControlName="DateStart">-->
<!--        <mat-datepicker-toggle matSuffix [for]="DateStartPicker"></mat-datepicker-toggle>-->
<!--        <mat-datepicker #DateStartPicker></mat-datepicker>-->
<!--      </mat-form-field>-->
<!--      <mat-form-field>-->
<!--        <mat-placeholder>End Date</mat-placeholder>-->
<!--        <input matInput [matDatepicker]="DateEndPicker" name="DateEnd"-->
<!--               formControlName="DateEnd">-->
<!--        <mat-datepicker-toggle matSuffix [for]="DateEndPicker"></mat-datepicker-toggle>-->
<!--        <mat-datepicker #DateEndPicker></mat-datepicker>-->
<!--      </mat-form-field>-->
<!--    </form>-->
<!--  </div>-->
<!--</mat-card>-->
<mat-card >
  <button mat-raised-button color="accent" (click)="summaryByField()" [disabled]="summary_by_field_loading">
    Summary by Field
    <mat-progress-spinner [diameter]="30" color="primary" mode="indeterminate" *ngIf="summary_by_field_loading"
                          style="position: absolute; top:3px; left: 35%"></mat-progress-spinner>
  </button>
  <div style="display: flex; flex-direction: column" *ngIf="projectService.layerIsLoaded && landOwnershipLuService.layerIsLoaded | async">
    <form novalidate [formGroup]="featureForm_sf">
      <mat-form-field>
        <mat-placeholder>Start Date</mat-placeholder>
        <input matInput [matDatepicker]="DateStartPicker_sf" name="DateStart"
               formControlName="DateStart">
        <mat-datepicker-toggle matSuffix [for]="DateStartPicker_sf"></mat-datepicker-toggle>
        <mat-datepicker #DateStartPicker_sf></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-placeholder>End Date</mat-placeholder>
        <input matInput [matDatepicker]="DateEndPicker_sf" name="DateEnd"
               formControlName="DateEnd">
        <mat-datepicker-toggle matSuffix [for]="DateEndPicker_sf"></mat-datepicker-toggle>
        <mat-datepicker #DateEndPicker_sf></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{projectService.meta.Proponent.alias}}</mat-placeholder>
        <input matInput type="text" name="Proponent" formControlName="Proponent">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{projectService.meta.Section_106.alias}}</mat-placeholder>
        <mat-select formControlName="Section_106">
          <mat-option *ngFor="let option of projectService.meta.Section_106.domain.codedValues" [value]="option.code">{{
            option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{projectService.meta.Lead_Agency.alias}}</mat-placeholder>
        <input matInput type="text" name="Lead_Agency" formControlName="Lead_Agency">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{projectService.meta.Other_Agency.alias}}</mat-placeholder>
        <input matInput type="text" name="Other_Agency" formControlName="Other_Agency">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{projectService.meta.Category.alias}}</mat-placeholder>
        <mat-select formControlName="Category">
          <mat-option *ngFor="let option of projectService.meta.Category.domain.codedValues" [value]="option.code">{{
            option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder >{{reviewService.meta.Ancestral_Territory.alias}}</mat-placeholder>
        <mat-select formControlName="Ancestral_Territory" matTooltip="Ancestral Territory">
          <mat-option *ngFor="let option of reviewService.meta.Ancestral_Territory.domain.codedValues"
                      [value]="option.code">{{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{reviewResponseLuService.meta.Review_Response.alias}}</mat-placeholder>
        <mat-select formControlName="Review_Response" multiple>
          <mat-option *ngFor="let option of reviewResponseLuService.meta.Review_Response.domain.codedValues" [value]="option.code">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{landOwnershipLuService.meta.Land_Ownership.alias}}</mat-placeholder>
        <mat-select formControlName="Land_Ownership" multiple>
          <mat-option *ngFor="let option of landOwnershipLuService.meta.Land_Ownership.domain.codedValues"
                      [value]="option.code">{{
            option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{projectService.meta.notification.alias}}</mat-placeholder>
        <mat-select formControlName="Notification_Method">
          <mat-option *ngFor="let option of projectService.meta.notification.domain.codedValues"
                      [value]="option.code">{{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{reviewService.meta.Response_Method.alias}}</mat-placeholder>
        <mat-select formControlName="Response_Method">
          <mat-option *ngFor="let option of reviewService.meta.Response_Method.domain.codedValues"
                      [value]="option.code">{{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
</mat-card>
<mat-card>
  <button mat-raised-button color="accent" (click)="printFieldworkSummary()" [disabled]="fieldwork_summary_loading">
    Fieldwork Summary
    <mat-progress-spinner [diameter]="30" color="primary" mode="indeterminate" *ngIf="fieldwork_summary_loading"
                          style="position: absolute; top:3px; left: 35%"></mat-progress-spinner>
  </button>
  <div style="display: flex; flex-direction: column" *ngIf="projectService.layerIsLoaded | async">
    <form novalidate [formGroup]="featureForm_fs">
      <mat-form-field>
        <mat-placeholder>Start Date</mat-placeholder>
        <input matInput [matDatepicker]="DateStartPickerfs" name="DateStart"
               formControlName="DateStart">
        <mat-datepicker-toggle matSuffix [for]="DateStartPickerfs"></mat-datepicker-toggle>
        <mat-datepicker #DateStartPickerfs></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>End Date</mat-placeholder>
        <input matInput [matDatepicker]="DateEndPickerfs" name="DateEnd"
               formControlName="DateEnd">
        <mat-datepicker-toggle matSuffix [for]="DateEndPickerfs"></mat-datepicker-toggle>
        <mat-datepicker #DateEndPickerfs></mat-datepicker>
      </mat-form-field>
    </form>
  </div>
</mat-card>
