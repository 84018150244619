/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cultural-resources.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shpo/shpo.component.ngfactory";
import * as i3 from "../shpo/shpo.component";
import * as i4 from "../services/project.service";
import * as i5 from "@angular/router";
import * as i6 from "../services/main-map.service";
import * as i7 from "../services/edit.service";
import * as i8 from "../services/loading.service";
import * as i9 from "../services/shpo.service";
import * as i10 from "../services/tcp.service";
import * as i11 from "../services/sites-in-ape.service";
import * as i12 from "@angular/material/dialog";
import * as i13 from "../tcp/tcp.component.ngfactory";
import * as i14 from "../tcp/tcp.component";
import * as i15 from "../sites-in-ape/sites-in-ape.component.ngfactory";
import * as i16 from "../sites-in-ape/sites-in-ape.component";
import * as i17 from "./cultural-resources.component";
var styles_CulturalResourcesComponent = [i0.styles];
var RenderType_CulturalResourcesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CulturalResourcesComponent, data: {} });
export { RenderType_CulturalResourcesComponent as RenderType_CulturalResourcesComponent };
export function View_CulturalResourcesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shpo", [["style", "display: flex; flex: 1;"]], null, null, null, i2.View_ShpoComponent_0, i2.RenderType_ShpoComponent)), i1.ɵdid(1, 114688, null, 0, i3.ShpoComponent, [i4.ProjectService, i5.ActivatedRoute, i5.Router, i6.MainMapService, i7.EditService, i8.LoadingService, i9.ShpoService, i10.TcpService, i11.SitesInApeService, i12.MatDialog], { project_id: [0, "project_id"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-tcp", [["style", "display: flex; flex: 1;"]], null, null, null, i13.View_TcpComponent_0, i13.RenderType_TcpComponent)), i1.ɵdid(3, 114688, null, 0, i14.TcpComponent, [i4.ProjectService, i5.ActivatedRoute, i5.Router, i6.MainMapService, i7.EditService, i8.LoadingService, i9.ShpoService, i10.TcpService, i11.SitesInApeService, i12.MatDialog], { project_id: [0, "project_id"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-sites-in-ape", [["style", "display: flex; flex: 1;"]], null, null, null, i15.View_SitesInApeComponent_0, i15.RenderType_SitesInApeComponent)), i1.ɵdid(5, 114688, null, 0, i16.SitesInApeComponent, [i4.ProjectService, i5.ActivatedRoute, i5.Router, i6.MainMapService, i7.EditService, i8.LoadingService, i9.ShpoService, i10.TcpService, i11.SitesInApeService, i12.MatDialog], { project_id: [0, "project_id"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.project_id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.project_id; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.project_id; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_CulturalResourcesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cultural-resources", [], null, null, null, View_CulturalResourcesComponent_0, RenderType_CulturalResourcesComponent)), i1.ɵdid(1, 114688, null, 0, i17.CulturalResourcesComponent, [i4.ProjectService, i5.ActivatedRoute, i5.Router, i6.MainMapService, i7.EditService, i8.LoadingService, i9.ShpoService, i10.TcpService, i11.SitesInApeService, i12.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CulturalResourcesComponentNgFactory = i1.ɵccf("app-cultural-resources", i17.CulturalResourcesComponent, View_CulturalResourcesComponent_Host_0, {}, {}, []);
export { CulturalResourcesComponentNgFactory as CulturalResourcesComponentNgFactory };
