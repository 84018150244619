import {Component, OnDestroy, OnInit, Inject} from '@angular/core';
import {finalize, flatMap, map, switchMap, takeWhile} from 'rxjs/operators';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {LoadingService} from '../services/loading.service';
import {Subject, BehaviorSubject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {ProjectFormComponent} from '../project-form/project-form.component';
import {MainMapService} from '../services/main-map.service';
import {ProjectService} from '../services/project.service';
import {EditService} from '../services/edit.service';
import {TaskService} from '../services/task.service';
import {TaskPopUpComponent} from '../task-pop-up/task-pop-up.component';
import {FieldworkPopUpComponent} from '../fieldwork-pop-up/fieldwork-pop-up.component';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-assigned-task',
  templateUrl: './assigned-task.component.html',
  styleUrls: ['./assigned-task.component.css']
})
export class AssignedTaskComponent implements OnInit {
displayColumns = ['Task', 'Start_Date', 'Assigned_To'];
project_id: string;

  constructor(public projectService: ProjectService, private route: ActivatedRoute, private router: Router,
              public mapService: MainMapService, public editService: EditService, public  loadingService: LoadingService,
              public taskService: TaskService, public dialog: MatDialog) {
  }

  ngOnInit() {
      this.loadingService.show();
      this.route.parent.paramMap.pipe(
        switchMap((params: ParamMap) => {
          this.project_id = params.get('id');
          return this.taskService.layerIsLoaded.pipe(
            switchMap(() => {
              this.taskService.filter.where = `ProjectFK = '${this.project_id}'`;
              return this.taskService.getItems().pipe(finalize(() => this.loadingService.hide()));
            })
          );
        })).subscribe();
  }
  openDialog(task): void {
    if (task.attributes.GlobalID === 'new') {
      this.taskService.create_new_task(this.project_id).subscribe(new_task => {
        this.dialog.open(TaskPopUpComponent, {
          width: '650px',
          data: {task: new_task}
        }).afterClosed().pipe(flatMap(result => {
          console.log('The dialog was closed');
          return this.taskService.getItems();
        })).subscribe();
      });
    } else {
      this.dialog.open(TaskPopUpComponent, {
        width: '650px',
        data: {task: task}
      });
    }
  }
}
