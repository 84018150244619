export const environment = {
  Logo: 'assets/images/logo.png',
  production: true,
  name: 'Innovate!',
  MaxAttach: 4,
  MapCenter: [-123.2812, 42.9273],
  MapZoom: 9,
  Rest_URL: 'https://arcserver.innovateteam.com/arcgis/rest/services/TCRIS/TCRIS/FeatureServer/',
  Rest_Report_URL: 'https://arcserver.innovateteam.com/arcgis/rest/services/TCRIS/',
  Portal_URL: 'https://portal.innovateteam.com/arcgis',
  Portal_AppID: '5d2rgNDIEPIHgjwj'
};
