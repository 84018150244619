import { Injectable } from '@angular/core';
import {ArcBaseService} from './arc-base.service';
import {LoadingService} from './loading.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfigService} from './config.service';
import {Observable} from 'rxjs';
import {loadModules} from 'esri-loader';

export class LandOwnershipLU {
  attributes: {
    project_fk: number | string;
    Land_Ownership: string;
    GlobalID: number | string;
  };
}

@Injectable()
export class LandOwnershipLuService extends ArcBaseService {
  constructor(snackBar: MatSnackBar, loadingService: LoadingService) {
    super('10', snackBar, loadingService);
  }
  create_new_action(project_fk, actions) {
    const vm = this;
    // const project = new Project();
    const config = new ConfigService();
    return new Observable<any>(obs => {
      vm.layerIsLoaded.subscribe(() => {
        loadModules(['esri/graphic', 'esri/geometry/Polygon', 'esri/SpatialReference'], config.jsapi_config).then(([Graphic, Polygon, SpatialReference]) => {
          const landOwnership = new Graphic(vm.layer.templates[0].prototype.toJson());
          // project.setSymbol(vm.layer.renderer.getSymbol());
          // project.setGeometry(new Polygon(new SpatialReference(3857)));
          landOwnership.attributes.GlobalID = 'new';
          landOwnership.attributes.project_fk = project_fk;
          landOwnership.attributes.Land_Ownership = actions;
          vm.addFeature(landOwnership, true).subscribe();
          obs.next(landOwnership);
          obs.complete();
        });
      });
    });
  }
}



