<div id="mainMap" style="flex: 1">

  <!--<div id="zoomButtons" style="z-index: 99999; position: absolute; top:15px; left: 10px;">-->
    <!--<button mat-icon-button (click)="mainMapService.zoomIn()" color="primary"><mat-icon>plus</mat-icon></button>-->
  <!--</div>-->
</div>

<div class="basemap-speed-dial">
  <!-- Fab -->
  <button mat-fab
          [@preventInitialAnimation]
          [satPopoverAnchorFor]="dial"
          (click)="dial.toggle()">
    <mat-icon [@spinInOut]="'in'" *ngIf="dial.isOpen()">close</mat-icon>
    <mat-icon [@spinInOut]="'in'" *ngIf="!dial.isOpen()">map</mat-icon>
  </button>
  <!-- Actions -->
  <sat-popover #dial verticalAlign="above">
    <div class="dial">
      <ng-container *ngFor="let q of mainMapService.basemaps | async">
        <button mat-mini-fab
                color="accent"
                [satPopoverAnchorFor]="tooltip"
                (mouseenter)="tooltip.open()"
                (mouseleave)="tooltip.close()"
                (click)="mainMapService.switchBasemap(q); dial.close()"
          [ngStyle]="{'background': 'url('+q.thumbnailUrl+')'}">
        </button>
        <sat-popover #tooltip horizontalAlign="before">
          <div class="tooltip mat-body-1">
            {{q.title}}
          </div>
        </sat-popover>
      </ng-container>
    </div>
  </sat-popover>
</div>



