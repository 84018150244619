import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {ProjectService} from '../services/project.service';
import {LoadingService} from '../services/loading.service';
import {CulturalPopUpComponent} from '../cultural-pop-up/cultural-pop-up.component';
import {ShpoService} from '../services/shpo.service';
import {CommentService} from '../services/comment.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-comment-pop-up',
  templateUrl: './comment-pop-up.component.html',
  styleUrls: ['./comment-pop-up.component.css']
})
export class CommentPopUpComponent implements OnInit {
  project: Subject<any>;
  featureForm = new FormGroup({
    Comments: new FormControl(),
    Type: new FormControl(),
    ProjectFK: new FormControl(),
    GlobalID: new FormControl(),
    ObjectID: new FormControl()
  })

  constructor(public dialogRef: MatDialogRef<CommentPopUpComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public commentService: CommentService, public projectService: ProjectService, public  loadingService: LoadingService) {}


  ngOnInit() {
    this.featureForm.patchValue(this.data.comment.attributes);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  save() {
    this.data.comment.attributes = this.featureForm.value;
    if (this.data.comment.attributes.GlobalID === 'new') this.commentService.addFeature(this.data.comment).subscribe(() => this.dialogRef.close());
    else this.commentService.updateFeature(this.data.comment).subscribe(() => this.dialogRef.close());
  }

}
