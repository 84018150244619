import { loadModules } from 'esri-loader';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { MainMapService } from './main-map.service';
import { finalize } from 'rxjs/operators';
var EditService = /** @class */ (function () {
    function EditService(config, mapService) {
        this.config = config;
        this.mapService = mapService;
        var vm = this;
        vm.ready = new Promise(function (resolve, reject) {
            vm.mapService.mapObs.subscribe(function (map) {
                loadModules(['esri/toolbars/edit', 'esri/toolbars/draw']).then(function (_a) {
                    var Edit = _a[0], Draw = _a[1];
                    vm.edit = new Edit(map);
                    vm.draw = new Draw(map);
                    vm.EDIT_VERTICES = Edit.EDIT_VERTICES;
                    vm.POLYGON = Draw.POLYGON;
                    resolve();
                });
            });
        });
    }
    // move(feature): Observable<any> {
    //   return Observable.create(obs => {
    //     this.ready.then(() => {
    //       edit.on('graphic-move-stop', function (e) {
    //         obs.next(e.graphic);
    //       });
    //       edit.activate(Edit.MOVE, feature);
    //     });
    //   }).pipe(
    //     finalize(() => edit.deactivate())
    //   );
    // }
    EditService.prototype.edit_verticies = function (feature) {
        var _this = this;
        var vm = this;
        return Observable.create(function (obs) {
            _this.ready.then(function () {
                vm.edit.activate(vm.EDIT_VERTICES, feature);
            });
        }).pipe(finalize(function () {
            if (vm.edit)
                vm.edit.deactivate();
        }));
    };
    EditService.prototype.add_polygon = function () {
        var _this = this;
        var vm = this;
        return Observable.create(function (obs) {
            _this.ready.then(function () {
                vm.draw.on('draw-complete', function (e) {
                    obs.next(e.geometry);
                });
                vm.draw.activate(vm.POLYGON);
            });
        }).pipe(finalize(function () {
            if (vm.draw)
                vm.draw.deactivate();
        }));
    };
    EditService.prototype.stop = function () {
        var vm = this;
        this.ready.then(function () { return vm.edit.deactivate(); });
    };
    return EditService;
}());
export { EditService };
