<mat-card class="main-card" style="display: flex; flex-direction: column; flex: 1">
  <mat-table *ngIf="sitesinapeService.layerIsLoaded | async" #table [dataSource]="sitesinapeService.datasource" style="max-height: 100%; flex:1 1; overflow:auto;">
    <ng-container matColumnDef="Site_Number">
      <mat-header-cell *matHeaderCellDef>{{sitesinapeService.meta.Site_Number.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.Site_Number}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="NRPH">
      <mat-header-cell *matHeaderCellDef>{{sitesinapeService.meta.NRPH.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.NRPH}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Site_Description">
      <mat-header-cell *matHeaderCellDef>{{sitesinapeService.meta.Site_Description.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.Site_Description}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
    <mat-row style="cursor: pointer;" *matRowDef="let element; columns: displayColumns" (click)="openDialog(element)" ></mat-row>
  </mat-table>
  <button mat-mini-fab *ngIf="project_id | async as p_id" (click)="openDialog({attributes: {'GlobalID': 'new', 'ProjectFK': p_id }})" ><mat-icon>add</mat-icon></button>
</mat-card>
