import { Component, OnInit } from '@angular/core';
import {Stipulation, StipulationsService} from '../services/stipulations.service';
import {ProjectService} from '../services/project.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {MainMapService} from '../services/main-map.service';
import {EditService} from '../services/edit.service';
import {LoadingService} from '../services/loading.service';
import {finalize, map, switchMap} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';

@Component({
  selector: 'app-stipulations',
  templateUrl: './stipulations.component.html',
  styleUrls: ['./stipulations.component.css']
})
export class StipulationsComponent implements OnInit {
  stipulation: Subject<Stipulation> = new Subject<Stipulation>();
  parent_id;
  featureForm = new FormGroup({
    ProjectID: new FormControl(),
    To_Monitor: new FormControl(),
    Monitor: new FormControl(),
    Meeting: new FormControl(),
    Meet_Place: new FormControl(),
    Meet_Date: new FormControl(),
    SIte_Visit: new FormControl(),
    Visit_Date: new FormControl(),
    IDP: new FormControl(),
    Contractual: new FormControl(),
    Contract_Number: new FormControl(),
    Other: new FormControl(),
    Other_Text: new FormControl(),
    Monitor_Start: new FormControl(),
    Monitor_End: new FormControl(),
    Project_Number: new FormControl(),
    GlobalID: new FormControl(),
    ProjectFK: new FormControl(),
    OBJECTID: new FormControl()
  });

  constructor(public projectService: ProjectService, private route: ActivatedRoute, private router: Router,
              public mapService: MainMapService, public editService: EditService, public  loadingService: LoadingService,
              public stipulationsService: StipulationsService) {
  }

  ngOnInit() {
    this.loadingService.show();
    this.stipulation.subscribe(stipulation => this.featureForm.patchValue(stipulation.attributes));
    this.route.parent.paramMap.pipe(
      switchMap((params: ParamMap) => this.parent_id = params.get('id')),
    ).subscribe();
    this.stipulationsService.layerIsLoaded.subscribe(() => {
      this.stipulationsService.filter.where = `ProjectFK = '${this.parent_id}'`;
      this.stipulationsService.query().pipe(
        map(stipulations => {
          if (stipulations.length > 0) this.stipulation.next(stipulations[0]);
          else this.stipulationsService.create_new_stipulation().subscribe(stipulation => this.stipulation.next(stipulation));
        }),
        finalize(() => this.loadingService.hide())
      ).subscribe();
    });
  }
  save(stipulation) {
    stipulation.attributes = this.featureForm.value;
    stipulation.attributes.ProjectFK = this.parent_id;
    if ( stipulation.attributes.GlobalID === 'new') {
      this.stipulationsService.addFeature(stipulation).subscribe();
    } else this.stipulationsService.updateFeature(stipulation).subscribe();
  }
}

