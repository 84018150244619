<h1 mat-dialog-title>Communications
</h1>
<div mat-dialog-content>
  <form novalidate [formGroup]="featureForm">
    <div style="display: flex; flex-direction: column; justify-content: space-between;">


      <mat-form-field>
        <mat-placeholder>{{commService.meta.CommDate.alias}}</mat-placeholder>
        <input matInput [matDatepicker]="StartDatePicker" name="Communication Date" formControlName="CommDate">
        <mat-datepicker-toggle matSuffix [for]="StartDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #StartDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{commService.meta.Person.alias}}</mat-placeholder>
        <input matInput type="text" name="Person" formControlName="Person">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{commService.meta.CommMethod.alias}}</mat-placeholder>
        <mat-select formControlName="CommMethod">
          <mat-option *ngFor="let option of commService.meta.CommMethod.domain.codedValues"
                      [value]="option.code">{{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!--<mat-form-field>-->
        <!--<mat-placeholder>{{commService.meta.CommMethod.alias}}</mat-placeholder>-->
        <!--<input matInput type="text" name="CommMethod" formControlName="CommMethod">-->
      <!--</mat-form-field>-->
      <mat-form-field>
        <mat-label>{{commService.meta.Communication.alias}}</mat-label>
        <textarea matInput formControlName="Communication" matTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=5></textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{commService.meta.DateResponded.alias}}</mat-placeholder>
        <input matInput [matDatepicker]="resDatePicker" name="DateResponded" formControlName="DateResponded">
        <mat-datepicker-toggle matSuffix [for]="resDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #resDatePicker></mat-datepicker>
      </mat-form-field>

      <!--<mat-form-field>-->
        <!--<mat-placeholder>{{commService.meta.Communication.alias}}</mat-placeholder>-->
        <!--<input matInput type="text" name="Communication" formControlName="Communication">-->
      <!--</mat-form-field>-->
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="save()" cdkFocusInitial>Save</button>
</div>
