import {Injectable} from '@angular/core';
import {loadModules} from 'esri-loader';
// import FeatureLayer = require('esri/layers/FeatureLayer');
import {ConfigService} from './config.service';
import {ReplaySubject, Observable, BehaviorSubject} from 'rxjs';
import {MainMapService} from './main-map.service';
import {listener} from '@angular/core/src/render3/instructions';
import {DataSource} from '@angular/cdk/collections';
import {finalize, map} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoadingService} from './loading.service';


// import SpatialReference = require('esri/SpatialReference');
// import ProjectParameters = require('esri/tasks/ProjectParameters');
// import Point = require('esri/geometry/Point');
// import GeometryService = require('esri/tasks/GeometryService');
// import Query = require('esri/tasks/query');
// import Graphic = require('esri/graphic');

// export interface MyGraphic extends Graphic {
//   coordinates: Point;
// }
//
// export interface MyFeatureSet extends FeatureSet {
//   features: MyGraphic[];
// }
export abstract class ArcBaseService {
  loading: boolean;
  foreignKeyField: string;
  layer;
  layerIsLoaded: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  config = new ConfigService();
  listenerActive: boolean;
  listener;
  meta;
  filter;
  count: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  current_page = 0;
  private FeatureLayer;
  private Query;
  StatisticDefinition;
  private Polygon;
  private SpatialReference;
  dataChange: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  datasource: BaseDataSource;


  constructor(url: string, public snackBar: MatSnackBar, public loadingService: LoadingService) {
    const vm = this;
    vm.datasource = new BaseDataSource(vm);
    const config = new ConfigService();
    loadModules(['esri/layers/FeatureLayer', 'esri/tasks/query', 'esri/geometry/Polygon', 'esri/SpatialReference'], config.jsapi_config)
      .then(([_FeatureLayer, _Query, _Polygon, _SpatialReference]) => {
        vm.FeatureLayer = _FeatureLayer;
        vm.Query = _Query;
        vm.Polygon = _Polygon;
        vm.SpatialReference = _SpatialReference;
        vm.filter = new _Query();
        vm.filter.num = 25;
        vm.filter.start = 0;
        vm.layer = new vm.FeatureLayer(`${config.rest_setting.url}${url}`, {
          outFields: ['*'],
          mode: vm.FeatureLayer.MODE_SNAPSHOT
        });
        vm.layer.on('load', function () {
          vm.meta = vm.prep_fields_meta(vm.layer.fields);
          vm.layerIsLoaded.next(true);
          vm.layerIsLoaded.complete();
        });
      });
  }

  private save(feature, type, quiet = false) {
    let vm = this;
    return new Observable(obs => {
      if (feature.geometry !== null && feature.geometry !== undefined && feature.geometry.type === 'polygon' && feature.geometry.isSelfIntersecting()) {
        obs.error('Polygons cannot be self intersecting.');
      } else {
        this.layerIsLoaded.subscribe(() => {
          // const tempFeature = new graphic({attributes: feature.attributes});
          // this.projectPoint(feature.coordinates, new sr(3857)).subscribe(point => {
          //   if (point !== undefined) tempFeature.geometry = point;
          //   else tempFeature.geometry = feature.geometry;
          // get geometry to null if its empty so the server does not reject it
          const features = this.prepForServer([feature.clone()]);
          if (type === 'add') this.layer.applyEdits(features, null, null, function (results) {
            obs.next(results);
            if (!quiet) vm.openSnackBar('Added!', '');
          }, function (e) {
            const details = e.details !== undefined ? e.details[0] : '';
            vm.openSnackBar(`${e.toString()} ${details}`, '');
            obs.error(e);
          });
          else if (type === 'update') this.layer.applyEdits(null, features, null, function (add_results, update_results) {
            obs.next(update_results);
            if (!quiet) vm.openSnackBar('Updated!', '');
          }, function (e) {
            vm.openSnackBar(e.toString() + ' ' + e.details[0], '');
            obs.error(e);
          });
          // });
        });
      }
    });
  }

  openSnackBar(message: string, action: string) {
    let vm = this;
    vm.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  private prep_fields_meta(fields) {
    const fields_meta = {};
    this.layer.fields.forEach(function (field) {
      fields_meta[field.name] = field;
    });
    return fields_meta;
  }

  query() {
    const vm = this;
    return new Observable<any>(observer => {
      this.layerIsLoaded.subscribe(() => {
        loadModules(['esri/tasks/query'], this.config.jsapi_config)
          .then(([query]) => {
            const q = new query();
            this.loading = true;

            this.layer.queryFeatures(this.filter, function (featureSet) {
              // featureSet.features.forEach(function (feature, i) {
              //   featureSet.features[i] =
              // })

              // featureSet.features.fields = this.prep_fields_meta();
              featureSet.features = vm.convertFromEpoch(featureSet.features);
              observer.next(featureSet.features);
              vm.layer.queryCount(vm.filter, function (count) {
                vm.count.next(count);
              });
              observer.complete();
            }, function (e) {
              vm.openSnackBar(e.toString() + ' ' + e.details[0], '');
              observer.error(e);
            });
          });
      });
    });
  }

  queryMax() {
    const vm = this;
    return new Observable<any>(observer => {
      this.layerIsLoaded.subscribe(() => {
        loadModules(['esri/tasks/query', 'esri/tasks/StatisticDefinition'], this.config.jsapi_config)
          .then(([query, statisticDefinition]) => {
            const q = new query();
            const statDef = new statisticDefinition();
            this.loading = true;
            statDef.statisticType = 'max';
            statDef.onStatisticField = 'ProjectNumber';
            statDef.outStatisticFieldName = 'ProjectNumMAX';
            q.returnGeometry = false;
            q.where = '1=1';
            q.outFields = ['*'];
            q.outStatistics = [statDef];
            this.layer.queryFeatures(q, function (featureSet) {
              observer.next(featureSet.features);
              observer.complete();
            }, function (e) {
              vm.openSnackBar(e.toString() + ' ' + e.details[0], '');
              observer.error(e);
            });
          });
      });
    });
  }

  projectPoint(point, outSR) {
    return new Observable<any>(observer => {
      loadModules(['esri/tasks/ProjectParameters', 'esri/tasks/GeometryService', 'esri/SpatialReference'], this.config.jsapi_config)
        .then(([projectParameters, geometryService, sr]) => {
          outSR = outSR !== undefined ? outSR : new sr(4326);
          const params = new projectParameters();
          const geoService = new geometryService('https://tasks.arcgisonline.com/ArcGIS/rest/services/Geometry/GeometryServer');
          params.geometries = [point];
          params.outSR = outSR;
          geoService.project(params, function (projectedPoint) {
            observer.next(projectedPoint);
          });
        });
    });
  }

  selectFeature(globalId, objectId, outFields = ['*']) {
    const vm = this;
    return new Observable<any>(obs => {
      this.layerIsLoaded.subscribe(() => {
        const q = new vm.Query();

        if (globalId) q.where = `GlobalID='${globalId}'`;
        else if (objectId) q.objectIds = [objectId];
        q.outFields = outFields;
        this.layer.clearSelection();
        if (globalId || objectId) {
          this.layer.selectFeatures(q, vm.FeatureLayer.SELECTION_NEW, function (features) {
            features = vm.convertFromEpoch(features);
            if (features[0].geometry !== null && features[0].geometry.rings.length === 0) {
              // features[0].setGeometry(new vm.Polygon(new vm.SpatialReference(3857)));
              features[0].setSymbol(vm.layer.renderer.getSymbol());
            }
            obs.next(features[0]);
          }, function (e) {
            vm.openSnackBar(e.toString() + ' ' + e.details[0], '');
            obs.error(e);
          });
        }
      });
    });
  }

  convertFromEpoch(features) {
    const vm = this;
    const keys = Object.keys(this.meta);
    features.map(function (feature) {
      for (let key of keys) {
        if (vm.meta[key].type === 'esriFieldTypeDate' && feature.attributes[key] !== null) {
          if (feature.attributes[key] === -2209132800000) {
            feature.attributes[key] = null;
          } else feature.attributes[key] = new Date(feature.attributes[key]);
        }
      }
    });
    return features;
  }

  prepForServer(features) {
    const vm = this;
    const keys = Object.keys(this.meta);
    features.map(function (feature) {
      for (let key of keys) {
        if (vm.meta[key].type === 'esriFieldTypeDate' && feature.attributes[key] instanceof Date) feature.attributes[key] = feature.attributes[key].getTime();
      }
      // if (feature.geometry.type === 'polygon' && feature.geometry.rings.length === 0) delete feature.geometry;
    });
    return features;
  }

  addFeature(feature, quiet = false) {
    return this.save(feature, 'add', quiet);
  }

  updateFeature(feature) {
    return this.save(feature, 'update');
  }

  deleteFeature(feature, quiet = false) {
    let vm = this;
    return new Observable(obs => {
      this.layer.applyEdits(null, null, [feature], function (a, b, results) {
        if (!quiet) vm.openSnackBar('Deleted!', '');
        obs.next(results);
      }, function (e) {
        vm.openSnackBar(e.toString() + ' ' + e.details[0], '');
        obs.error(e);
      });
    });
  }

  addClickListener(callback) {
    this.listener = this.layer.on('click', callback);
    this.listenerActive = true;
  }

  removeClickListener() {
    this.listener.remove();
  }

  get data(): any[] {
    return this.dataChange.value;
  }

  getItems() {
    this.loadingService.show();
    return this.query().pipe(
      map(features => {
        this.dataChange.next(features);
      }),
      finalize(() => this.loadingService.hide())
    );
  }

  getPage(event) {
    // this.loadingService.setLoading(true);
    this.filter.start = event.pageIndex * event.pageSize;
    this.filter.num = event.pageSize;
    this.getItems()
      .subscribe();
  }

  getAttachments(objectId) {
    let vm = this;
    return new Observable(obs => {
      this.layer.queryAttachmentInfos(objectId, function (attachments) {
        attachments.forEach(function (attachment) {
          if (attachment.contentType.substring(0, 5) === 'image') {
            attachment.previewUrl = attachment.url;
          } else {
            attachment.previewUrl = 'assets/images/Very-Basic-File-icon.png';
            attachment.extension = attachment.name.split('.').pop();
          }
        });
        obs.next(attachments);
      });
    });
  }

  uploadAttachments(objectId, data) {
    let vm = this;
    return new Observable(obs => {
      this.layer.addAttachment(objectId, data, function (result) {
        obs.next(result.attachmentId);
        vm.openSnackBar('Attachment Added!', '');
      }, function (e) {
        vm.openSnackBar(e.toString() + ' ' + e.details[0], '');
        obs.error(e);
      });
    });
  }


  deleteAttachments(objectId, attachmentId) {
    let vm = this;
    return new Observable(obs => {
      this.layer.deleteAttachments(objectId, [attachmentId], function (response) {
        obs.next(response);
        vm.openSnackBar('Attachment Deleted!', '');
      }, function (e) {
        vm.openSnackBar(e.toString() + ' ' + e.details[0], '');
        obs.error(e);
      });
    });
  }


}


export class BaseDataSource extends DataSource<any> {
  constructor(private _projectDatabase: ArcBaseService) {
    super();
  }

  connect(): Observable<any[]> {
    return this._projectDatabase.dataChange;
  }

  disconnect() {
  }
}
