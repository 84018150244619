import { loadModules } from 'esri-loader';
import { Observable, ReplaySubject } from 'rxjs';
import { ConfigService } from './config.service';
var IdentityManagementService = /** @class */ (function () {
    function IdentityManagementService(config) {
        var _this = this;
        this.config = config;
        this.authenticated = false;
        this.identityManagerObs = new ReplaySubject();
        loadModules(['esri/IdentityManager', 'esri/arcgis/OAuthInfo', 'esri/arcgis/Portal'], this.config.jsapi_config)
            .then(function (_a) {
            var IdentityManager = _a[0], OAuthInfo = _a[1], Portal = _a[2];
            var info = new OAuthInfo({
                appId: _this.config.portal_setting.appId,
                popup: false,
                authNamespace: 'CRIS',
                expiration: 1440,
                portalUrl: _this.config.portal_setting.url
            });
            var stored_creds = localStorage.getItem('arcgis_creds');
            if (stored_creds !== null) {
                IdentityManager.initialize(JSON.stringify(stored_creds));
            }
            else {
                IdentityManager.registerOAuthInfos([info]);
            }
            _this.identityManager = IdentityManager;
            _this.portal = new Portal.Portal(_this.config.portal_setting.url);
            _this.identityManagerObs.complete();
        });
    }
    IdentityManagementService.prototype.authenticate = function (token, username, expires) {
        var _this = this;
        console.log('start');
        return new Observable(function (obs) {
            _this.identityManagerObs.subscribe(function () {
                if (token !== undefined && username !== undefined) {
                    _this.identityManager.registerToken({
                        expires: expires,
                        server: _this.config.portal_setting.url + "/sharing",
                        ssl: true,
                        token: token,
                        userId: username
                    });
                }
                var vm = _this;
                _this.identityManager.checkSignInStatus(_this.config.portal_setting.url + "/sharing").then(function () {
                    vm.portal.signIn().then(function (portalUser) {
                        vm.full_name = portalUser.fullName;
                        vm.user_name = portalUser.username;
                        vm.authenticated = true;
                        localStorage.setItem('arcgis_creds', vm.identityManager.toJson());
                        obs.next();
                    }).otherwise(function () {
                        obs.error();
                    });
                }).otherwise(function () {
                    vm.identityManager.getCredential(vm.config.portal_setting.url + "/sharing");
                });
            });
        }).subscribe();
    };
    IdentityManagementService.prototype.logout = function () {
        var _this = this;
        this.user_name = null;
        this.full_name = null;
        this.authenticated = false;
        this.identityManagerObs.subscribe(function () {
            _this.identityManager.destroyCredentials();
        });
        localStorage.clearAll();
        location.reload();
    };
    return IdentityManagementService;
}());
export { IdentityManagementService };
