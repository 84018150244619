import { Injectable } from '@angular/core';
import {ArcBaseService} from './arc-base.service';
import {LoadingService} from './loading.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {loadModules} from 'esri-loader';
import {ConfigService} from './config.service';
import {Observable} from 'rxjs';

export class Review {
  attributes: {
    ProjectID: number;
    Ancestral_Territory: string;
    Probability: string;
    Prob_Stipulations: string;
    Historic_Properties: string;
    Hist_Prop_Consult: string;
    Methodolgy: string;
    Meth_Consult: string;
    Project_Name: string;
    Project_Number: number;
    Review_Date: string;
    Response_Date: string;
    Response_Method: string;
    Review_Response: string;
    Res_Comment: string;
    GlobalID: number | string;
    ProjectFK: number | string;
    OBJECTID: number | string;
  };
}



@Injectable()
export class ReviewService extends ArcBaseService {
  constructor(snackBar: MatSnackBar, loadingService: LoadingService) {
    super('6'
      , snackBar, loadingService);
  }

  create_new_review() {
    const vm = this;
    const config = new ConfigService();
    return new Observable<Review>(obs => {
      vm.layerIsLoaded.subscribe(() => {
        loadModules(['esri/graphic', 'esri/geometry/Polygon', 'esri/SpatialReference'], config.jsapi_config).then(([Graphic, Polygon, SpatialReference]) => {
          const review = new Graphic(vm.layer.templates[0].prototype.toJson());
          review.attributes.GlobalID = 'new';
          obs.next(review);
          obs.complete();
        });
      });
    });
  }
}
