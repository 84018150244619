import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {FieldworkPopUpComponent} from '../fieldwork-pop-up/fieldwork-pop-up.component';
import {LoadingService} from '../services/loading.service';
import {ProjectService} from '../services/project.service';
import {FieldworkService} from '../services/fieldwork.service';
import {ShpoService} from '../services/shpo.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-cultural-pop-up',
  templateUrl: './cultural-pop-up.component.html',
  styleUrls: ['./cultural-pop-up.component.css']
})
export class CulturalPopUpComponent implements OnInit {
  project: Subject<any>;
  featureForm = new FormGroup({
    Project_Number: new FormControl()
  });

  constructor(public dialogRef: MatDialogRef<CulturalPopUpComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public shpoService: ShpoService, public projectService: ProjectService, public  loadingService: LoadingService) {}


  ngOnInit() {
    this.featureForm.patchValue(this.data.shpo.attributes);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  save() {
    this.data.shpo.attributes = this.featureForm.value;
    if (this.data.shpo.attributes.GlobalID === 'new') this.shpoService.addFeature(this.data.shpo).subscribe(() => this.dialogRef.close());
    else this.shpoService.updateFeature(this.data.shpo).subscribe(() => this.dialogRef.close());
  }
}
