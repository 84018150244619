import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {finalize, flatMap, switchMap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ProjectService} from '../services/project.service';
import {MainMapService} from '../services/main-map.service';
import {LoadingService} from '../services/loading.service';
import {EditService} from '../services/edit.service';
import {TcpService} from '../services/tcp.service';
import {SitesInApeService} from '../services/sites-in-ape.service';
import {ShpoService} from '../services/shpo.service';
import {CulturalPopUpComponent} from '../cultural-pop-up/cultural-pop-up.component';
import {TcpPopUpComponent} from '../tcp-pop-up/tcp-pop-up.component';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-tcp',
  templateUrl: './tcp.component.html',
  styleUrls: ['./tcp.component.css']
})
export class TcpComponent implements OnInit {
  @Input() project_id: Subject<string> ;
  displayColumns = ['TCP_in_APE'];



  constructor(public projectService: ProjectService, private route: ActivatedRoute, private router: Router,
              public mapService: MainMapService, public editService: EditService, public  loadingService: LoadingService, public shpoService: ShpoService, public tcpService: TcpService, public sitesinapeService: SitesInApeService, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.project_id.pipe(switchMap(project_id => {
      return this.tcpService.layerIsLoaded.pipe(
        switchMap(() => {
          this.tcpService.filter.where = `ProjectFK = '${project_id}'`;
          return this.tcpService.getItems().pipe(finalize(() => this.loadingService.hide()));
        })
      );
    })).subscribe(project_id => console.log(project_id));
  }

  openDialog(tcp): void {
    if (tcp.attributes.GlobalID === 'new') {
      this.tcpService.create_new_tcp(tcp.attributes.ProjectFK).subscribe(new_tcp => {
        this.dialog.open(TcpPopUpComponent, {
          width: '650px',
          data: {tcp: new_tcp}
        }).afterClosed().pipe(flatMap(result => {
          console.log('The dialog was closed');
          return this.tcpService.getItems();
        })).subscribe();
      });
    } else {
      this.dialog.open(TcpPopUpComponent, {
        width: '650px',
        data: {tcp: tcp}
      });
    }
  }
}
