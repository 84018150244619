import { Injectable } from '@angular/core';
import {LoadingService} from './loading.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class ProjectInReviewService {

  constructor(snackBar: MatSnackBar, loadingService: LoadingService) {
    // super('https://utility.arcgis.com/usrsvcs/servers/aafd21c26e2e4ec3b87509dd84a03234/rest/services/CRIS/ProjectsInReview/GPServer/ProjectsInReview',
    //   snackBar, loadingService);
    // }
  }
}
