import { Injectable } from '@angular/core';
import {ArcBaseService} from './arc-base.service';
import {LoadingService} from './loading.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {loadModules} from 'esri-loader';
import {ConfigService} from './config.service';
import {Observable} from 'rxjs';
// import {Project} from './project.service';

export class Stipulation {
  attributes: {
    ProjectID: number;
    To_Monitor: number; // bit
    Monitor: string; // domain person
    Meeting: number; // bit
    Meet_Place: string;
    Meet_Date: string; // date
    SIte_Visit: number; // bit
    Visit_Date: string; // date
    IDP: number; // bit
    Contractual: number; // bit
    Contract_Number: string;
    Other: number; // bit
    Other_Text: string;
    Monitor_Start: string; // date
    Monitor_End: string;  // date
    Project_Number: number;
    GlobalID: number | string;
    ProjectFK: number | string;
    OBJECTID: number | string;
  };
}



@Injectable()
export class StipulationsService extends ArcBaseService {
  constructor(snackBar: MatSnackBar, loadingService: LoadingService) {
    super('1', snackBar, loadingService);
  }

  create_new_stipulation() {
    const vm = this;
    // const project = new Project();
    const config = new ConfigService();
    return new Observable<Stipulation>(obs => {
      vm.layerIsLoaded.subscribe(() => {
        loadModules(['esri/graphic', 'esri/geometry/Polygon', 'esri/SpatialReference'], config.jsapi_config).then(([Graphic, Polygon, SpatialReference]) => {
          const project = new Graphic(vm.layer.templates[0].prototype.toJson());
          // project.setSymbol(vm.layer.renderer.getSymbol());
          // project.setGeometry(new Polygon(new SpatialReference(3857)));
          project.attributes.GlobalID = 'new';
          obs.next(project);
          obs.complete();
        });
      });
    });
  }
}
