import {of as observableOf, Subject, Observable} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {Review, ReviewService} from '../services/review.service';
// import {ProjectService} from '../services/project.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {MainMapService} from '../services/main-map.service';
import {EditService} from '../services/edit.service';
import {LoadingService} from '../services/loading.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import {ReviewResponseLU, ReviewResponseLuService} from '../services/review-response-lu.service';


@Component({
  selector: 'app-review-form',
  templateUrl: './review-form.component.html',
  styleUrls: ['./review-form.component.css']
})


export class ReviewFormComponent implements OnInit {
  review: Subject<Review> = new Subject<Review>();
  actions: Subject<ReviewResponseLU> = new Subject<ReviewResponseLU>();
  parent_id;
  featureForm = new FormGroup({
    ProjectID: new FormControl(),
    Ancestral_Territory: new FormControl(),
    Probability: new FormControl(),
    Prob_Stipulations: new FormControl(),
    Historic_Properties: new FormControl(),
    Hist_Prop_Consult: new FormControl(),
    Methodolgy: new FormControl(),
    Meth_Consult: new FormControl(),
    Project_Name: new FormControl(),
    Project_Number: new FormControl(),
    Review_Date: new FormControl(),
    Response_Date: new FormControl(),
    Response_Method: new FormControl(),
    Review_Response: new FormControl(),
    Res_Comment: new FormControl(),
    GlobalID: new FormControl(),
    ProjectFK: new FormControl(),
    OBJECTID: new FormControl(),
  });

  message: string;

  constructor(private route: ActivatedRoute, private router: Router,
              public mapService: MainMapService, public editService: EditService, public  loadingService: LoadingService,
              public reviewService: ReviewService, public reviewResponseLuService: ReviewResponseLuService) {
  }

  ngOnInit() {
    this.loadingService.show();

    // Observable.forkJoin([response_obs]).subscribe(() => this.featureForm.patchValue(review.attributes));

    this.review.subscribe(review => this.featureForm.patchValue(review.attributes));
    this.route.parent.paramMap.pipe(
      switchMap((params: ParamMap) => this.parent_id = params.get('id')),

      switchMap(() => {
        return this.reviewService.layerIsLoaded.pipe(switchMap(() => {
            this.reviewService.filter.where = `ProjectFK = '${this.parent_id}'`;
            return this.reviewService.query().pipe(
              switchMap(reviews => {
                if (reviews.length > 0) {
                  this.review.next(reviews[0]);
                  return observableOf(reviews[0]);
                } else {
                  return this.reviewService.create_new_review().pipe(map(review => {
                    this.review.next(review);
                    return review;
                  }));
                }
              })
            );
          })
        );
      }),
      switchMap(review => {
        return this.reviewResponseLuService.layerIsLoaded.pipe(switchMap(() => {
          if (review.attributes.GlobalID !== 'new') {
            this.reviewResponseLuService.filter.where = `projects_fk = '${review.attributes.GlobalID}'`;
            return this.reviewResponseLuService.query().pipe(tap(results => {
              this.actions = results;
              review.attributes.Review_Response = results.map(r => r.attributes.Review_Response);
              this.featureForm.patchValue(review.attributes);
            }));
          }
        }));
      }),
    ).subscribe(() => this.loadingService.hide());


  }

  save(review) {
    review.attributes = this.featureForm.value;
    let review_response = review.attributes.Review_Response;
    delete review.attributes.Review_Response;
    review.attributes.ProjectFK = this.parent_id;
    if (review.attributes.GlobalID === 'new') {
      this.reviewService.addFeature(review).subscribe(results => {
        // delete all related multiselect values
        this.reviewResponseLuService.layerIsLoaded.subscribe(() => {
          this.reviewResponseLuService.filter.where = `projects_fk = '${results[0].globalId}'`;
          this.reviewResponseLuService.query().subscribe(projectActions_results => {
            projectActions_results.forEach((element) => {
              this.reviewResponseLuService.deleteFeature(element).subscribe();
            });
            if (review_response instanceof Array) {
              review_response.forEach((element) => {
                this.reviewResponseLuService.create_new_review_response(results[0].globalId, element).subscribe();
              });
            }
          });
        });
      });
    } else this.reviewService.updateFeature(review).subscribe(results => {
      // delete all related multiselect values
      this.reviewResponseLuService.layerIsLoaded.subscribe(() => {
        this.reviewResponseLuService.filter.where = `projects_fk = '${results[0].globalId}'`;
        this.reviewResponseLuService.query().subscribe(projectActions_results => {
          projectActions_results.forEach((element) => {
            this.reviewResponseLuService.deleteFeature(element).subscribe();
          });
          if (review_response instanceof Array) {
            review_response.forEach((element) => {
              this.reviewResponseLuService.create_new_review_response(results[0].globalId, element).subscribe();
            });
          }
        });
      });
    });
  }
}
