/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../main-map/main-map.component.ngfactory";
import * as i3 from "../main-map/main-map.component";
import * as i4 from "../services/config.service";
import * as i5 from "../services/main-map.service";
import * as i6 from "../services/project.service";
import * as i7 from "@angular/router";
import * as i8 from "../services/identity-management.service";
import * as i9 from "./map-view.component";
var styles_MapViewComponent = [i0.styles];
var RenderType_MapViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapViewComponent, data: {} });
export { RenderType_MapViewComponent as RenderType_MapViewComponent };
export function View_MapViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main-map", [], null, null, null, i2.View_MainMapComponent_0, i2.RenderType_MainMapComponent)), i1.ɵdid(1, 114688, null, 0, i3.MainMapComponent, [i4.ConfigService, i5.MainMapService, i6.ProjectService, i7.Router, i8.IdentityManagementService], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, null); }
export function View_MapViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-map-view", [], null, null, null, View_MapViewComponent_0, RenderType_MapViewComponent)), i1.ɵdid(1, 114688, null, 0, i9.MapViewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapViewComponentNgFactory = i1.ɵccf("app-map-view", i9.MapViewComponent, View_MapViewComponent_Host_0, {}, {}, []);
export { MapViewComponentNgFactory as MapViewComponentNgFactory };
