<mat-card class="main-card" style="display: flex; flex-direction: column; height: 100%">
  <mat-table #table [dataSource]="commService.datasource" style="max-height: 100%; flex:1 1; overflow:auto;">

    <ng-container matColumnDef="CommDate">
      <mat-header-cell *matHeaderCellDef>{{commService.meta.CommDate.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.CommDate | date:'mediumDate'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Person">
      <mat-header-cell *matHeaderCellDef>{{commService.meta.Person.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.Person}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="CommMethod">
      <mat-header-cell *matHeaderCellDef>{{commService.meta.CommMethod.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.CommMethod}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="DateResponded">
      <mat-header-cell *matHeaderCellDef>{{commService.meta.DateResponded.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.DateResponded | date:'mediumDate'}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
    <mat-row style="cursor: pointer;" *matRowDef="let element; columns: displayColumns" (click)="openDialog(element)" ></mat-row>
  </mat-table>
  <button mat-mini-fab [routerLink]="['new']" (click)="openDialog({attributes: {'GlobalID': 'new', 'ProjectFK': project_id }})" ><mat-icon>add</mat-icon></button>
</mat-card>

