import {Component, Input, OnInit} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MainMapService} from '../services/main-map.service';
import {LoadingService} from '../services/loading.service';
import {SitesInApeService} from '../services/sites-in-ape.service';
import {EditService} from '../services/edit.service';
import {ShpoService} from '../services/shpo.service';
import {ProjectService} from '../services/project.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TcpService} from '../services/tcp.service';
import {finalize, flatMap, map, switchMap} from 'rxjs/operators';
import {TcpPopUpComponent} from '../tcp-pop-up/tcp-pop-up.component';
import {SitesInApePopUpComponent} from '../sites-in-ape-pop-up/sites-in-ape-pop-up.component';

@Component({
  selector: 'app-sites-in-ape',
  templateUrl: './sites-in-ape.component.html',
  styleUrls: ['./sites-in-ape.component.css']
})
export class SitesInApeComponent implements OnInit {
  @Input() project_id: Subject<string> ;
  displayColumns = ['Site_Number', 'NRPH', 'Site_Description'];

  constructor(public projectService: ProjectService, private route: ActivatedRoute, private router: Router,
              public mapService: MainMapService, public editService: EditService, public  loadingService: LoadingService, public shpoService: ShpoService, public tcpService: TcpService, public sitesinapeService: SitesInApeService, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.project_id.pipe(switchMap(project_id => {
      return this.sitesinapeService.layerIsLoaded.pipe(
        switchMap(() => {
          this.sitesinapeService.filter.where = `ProjectFK = '${project_id}'`;
          return this.sitesinapeService.getItems().pipe(finalize(() => this.loadingService.hide()));
        })
      );
    })).subscribe(project_id => console.log(project_id));
  }
  openDialog(ape): void {
    if (ape.attributes.GlobalID === 'new') {
      this.sitesinapeService.create_new_site_APE(ape.attributes.ProjectFK).subscribe(new_ape => {
        this.dialog.open(SitesInApePopUpComponent, {
          width: '650px',
          data: {ape: new_ape}
        }).afterClosed().pipe(flatMap(result => {
          console.log('The dialog was closed');
          return this.sitesinapeService.getItems();
        })).subscribe();
      });
    } else {
       this.dialog.open(SitesInApePopUpComponent, {
        width: '650px',
        data: {ape: ape}
      });
    }
  }
}
