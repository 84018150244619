<h1 mat-dialog-title>SHPO Report
</h1>
<div mat-dialog-content>
  <form novalidate [formGroup]="featureForm">
    <div style="display: flex; flex-direction: column; justify-content: space-between;">
      <mat-form-field>
        <mat-placeholder>{{shpoService.meta.SHPO_Report.alias}}</mat-placeholder>
        <input matInput type="text" name="SHPO Report" formControlName="SHPO_Report">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{shpoService.meta.Report_Date.alias}}</mat-placeholder>
        <input matInput [matDatepicker]="ReportDatePicker" name="Report Date" formControlName="Report_Date">
        <mat-datepicker-toggle matSuffix [for]="ReportDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #ReportDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{shpoService.meta.Author.alias}}</mat-placeholder>
        <input matInput type="text" name="Author" formControlName="Author">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{shpoService.meta.Investigation_Acres.alias}}</mat-placeholder>
        <input matInput type="number" step="any" name="Investigation Acres" formControlName="Investigation_Acres">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{shpoService.meta.Acres_Surveyed.alias}}</mat-placeholder>
        <input matInput type="number" step="any" name="Acres Surveyed" formControlName="Acres_Surveyed">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{shpoService.meta.Findings.alias}}</mat-placeholder>
        <input matInput type="text" name="Findings" formControlName="Findings">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{shpoService.meta.LevelofEffort.alias}}</mat-placeholder>
        <input matInput type="text" name="LevelofEffort" formControlName="LevelofEffort">
      </mat-form-field>
      <!--<mat-form-field>-->
        <!--<mat-placeholder>{{shpoService.meta.ProjectFK.alias}}</mat-placeholder>-->
        <!--<input matInput type="text" name="ProjectFK" formControlName="ProjectFK">-->
      <!--</mat-form-field>-->
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="save()" cdkFocusInitial>Save</button>
</div>
