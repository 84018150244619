import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {MainMapService} from '../services/main-map.service';
import {LoadingService} from '../services/loading.service';
import {ProjectService} from '../services/project.service';
import {EditService} from '../services/edit.service';
import {ShpoService} from '../services/shpo.service';
import {finalize, switchMap} from 'rxjs/operators';
import {FieldworkPopUpComponent} from '../fieldwork-pop-up/fieldwork-pop-up.component';
import {CulturalPopUpComponent} from '../cultural-pop-up/cultural-pop-up.component';
import {TcpService} from '../services/tcp.service';
import {SitesInApeService} from '../services/sites-in-ape.service';
import {Subject, BehaviorSubject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-cultural-resources',
  templateUrl: './cultural-resources.component.html',
  styleUrls: ['./cultural-resources.component.css']
})
export class CulturalResourcesComponent implements OnInit {
  displayColumns = ['Project_Number'];
  project_id: Subject<string> = new BehaviorSubject<string>(null);

  constructor(public projectService: ProjectService, private route: ActivatedRoute, private router: Router,
              public mapService: MainMapService, public editService: EditService, public  loadingService: LoadingService,
              public shpoService: ShpoService, public tcpService: TcpService, public sitesinapeService: SitesInApeService, public dialog: MatDialog) {
}

  ngOnInit() {
    // this.loadingService.show();
     this.route.parent.paramMap.subscribe((params: ParamMap) => {
      this.project_id.next(params.get('id'));
    });
  }
}
