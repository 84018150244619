import {Component, Inject, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {CulturalPopUpComponent} from '../cultural-pop-up/cultural-pop-up.component';
import {LoadingService} from '../services/loading.service';
import {ProjectService} from '../services/project.service';
import {TcpService} from '../services/tcp.service';
import {SitesInApeService} from '../services/sites-in-ape.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-sites-in-ape-pop-up',
  templateUrl: './sites-in-ape-pop-up.component.html',
  styleUrls: ['./sites-in-ape-pop-up.component.css']
})
export class SitesInApePopUpComponent implements OnInit {
  project: Subject<any>;
  featureForm = new FormGroup({
    Site_Number: new FormControl(),
    NRPH: new FormControl(),
    Site_Type: new FormControl(),
    Site_Description: new FormControl(),
    Effect: new FormControl(),
    Effect_Comments: new FormControl(),
    ProjectFK: new FormControl(),
    GlobalID: new FormControl(),
    ObjectID: new FormControl()
  });
  constructor(public dialogRef: MatDialogRef<SitesInApePopUpComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public tcpService: TcpService, public projectService: ProjectService, public  loadingService: LoadingService, public sitesinapeService: SitesInApeService) {}


  ngOnInit() {
    this.featureForm.patchValue(this.data.ape.attributes);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  saveApe() {
    this.data.ape.attributes = this.featureForm.value;
    if (this.data.ape.attributes.GlobalID === 'new') this.sitesinapeService.addFeature(this.data.ape).subscribe(() => this.dialogRef.close());
    else this.sitesinapeService.updateFeature(this.data.ape).subscribe(() => this.dialogRef.close());
  }
}
