import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgStyle} from '@angular/common';


import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HeaderComponent} from './header/header.component';

import {ConfigService} from './services/config.service';
import {MainMapComponent} from './main-map/main-map.component';
import {MainMapService} from './services/main-map.service';
import {EditPaneComponent} from './edit-pane/edit-pane.component';
import {ProjectService} from './services/project.service';
import {MapViewComponent} from './map-view/map-view.component';
import {IdentityManagementService} from './services/identity-management.service';
import {UrlSerializer, UrlTree, DefaultUrlSerializer} from '@angular/router';
import {EditService} from './services/edit.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ListViewComponent} from './list-view/list-view.component';
import {ProjectFormComponent} from './project-form/project-form.component';
import {RelatedTableFormComponent} from './related-table-form/related-table-form.component';
import {LoadingService} from './services/loading.service';
import {SatPopoverModule} from '@ncstate/sat-popover';
import {AssignedTaskComponent} from './assigned-task/assigned-task.component';
import {TaskService} from './services/task.service';
import {StipulationsComponent} from './stipulations/stipulations.component';
import {StipulationsService} from './services/stipulations.service';
import {ProjectActionsLuService} from './services/project-actions-lu.service';
import {TaskPopUpComponent} from './task-pop-up/task-pop-up.component';
import {LandOwnershipLuService} from './services/land-ownership-lu.service';
import {FieldworkComponent} from './fieldwork/fieldwork.component';
import {FieldworkService} from './services/fieldwork.service';
import {FieldworkPopUpComponent} from './fieldwork-pop-up/fieldwork-pop-up.component';
import {CulturalResourcesComponent} from './cultural-resources/cultural-resources.component';
import {ShpoService} from './services/shpo.service';
import {CulturalPopUpComponent} from './cultural-pop-up/cultural-pop-up.component';
import {TcpService} from './services/tcp.service';
import {SitesInApeService} from './services/sites-in-ape.service';
import {TcpComponent} from './tcp/tcp.component';
import {TcpPopUpComponent} from './tcp-pop-up/tcp-pop-up.component';
import {ShpoComponent} from './shpo/shpo.component';
import {SitesInApeComponent} from './sites-in-ape/sites-in-ape.component';
import {ShpoPopUpComponent} from './shpo-pop-up/shpo-pop-up.component';
import {SitesInApePopUpComponent} from './sites-in-ape-pop-up/sites-in-ape-pop-up.component';
import {CommentsComponent} from './comments/comments.component';
import {CommentService} from './services/comment.service';
import { CommentPopUpComponent } from './comment-pop-up/comment-pop-up.component';
import { ReviewFormComponent } from './review-form/review-form.component';
import { ReviewService } from './services/review.service';
import { ReviewResponseLuService } from './services/review-response-lu.service';
import {DataService} from './services/data.service';
import {ReportsComponent} from './reports/reports.component';
import {ProjectInReviewService} from './services/project-in-review.service';
import {SnackBarComponent} from './snack-bar/snack-bar-component';
import { CommComponent } from './comm/comm.component';
import { CommPopUpComponent } from './comm-pop-up/comm-pop-up.component';
import {CommService} from './services/comm.service';
import { AttachmentFormComponent, UploadDialogComponent } from './attachment-form/attachment-form.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ThrottleClickDirective } from './directives/throttle-click.directive';
import { OnlynumberDirective } from './directives/onlynumber.directive';

// todo: figure out why this is necessary to keep from state in arcgis oauth2 hash from being encoded wrong.
export class CustomUrlSerializer implements UrlSerializer {
  parse(url: any): UrlTree {
    let dus = new DefaultUrlSerializer();
    return dus.parse(url);
  }

  serialize(tree: UrlTree): any {
    let dus = new DefaultUrlSerializer(),
      path = dus.serialize(tree);
    // use your regex to replace as per your requirement.
    return path.replace(/%7B/g, '{')
      .replace(/%7D/g, '}')
      .replace(/%253A/g, ':')
      .replace(/%252F/g, '/')
      .replace(/%22/g, '"');
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainMapComponent,
    EditPaneComponent,
    MapViewComponent,
    ListViewComponent,
    ProjectFormComponent,
    RelatedTableFormComponent,
    AssignedTaskComponent,
    StipulationsComponent,
    TaskPopUpComponent,
    FieldworkComponent,
    FieldworkPopUpComponent,
    CulturalResourcesComponent,
    CulturalPopUpComponent,
    TcpComponent,
    TcpPopUpComponent,
    ShpoComponent,
    SitesInApeComponent,
    ShpoPopUpComponent,
    SitesInApePopUpComponent,
    CommentsComponent,
    CommentPopUpComponent,
    ReviewFormComponent,
    ReportsComponent,
    SnackBarComponent,
    CommComponent,
    CommPopUpComponent,
    AttachmentFormComponent,
    UploadDialogComponent,
    ConfirmDialogComponent,
    ThrottleClickDirective,
    OnlynumberDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatTabsModule,
    MatTableModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatSelectModule,
    MatProgressBarModule,
    SatPopoverModule,
    MatDialogModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatGridListModule
  ],
  providers: [{provide: UrlSerializer, useClass: CustomUrlSerializer},
    ConfigService, MainMapService, ProjectService, IdentityManagementService,
    EditService, LoadingService, TaskService, StipulationsService, ProjectActionsLuService, LandOwnershipLuService, FieldworkService,
    ShpoService, TcpService, SitesInApeService, ReviewService, ReviewResponseLuService, CommentService,
    ProjectInReviewService, DataService, CommService],
  bootstrap: [AppComponent],
  entryComponents: [TaskPopUpComponent, FieldworkPopUpComponent, CulturalPopUpComponent, TcpPopUpComponent, SitesInApePopUpComponent,
    ShpoPopUpComponent, CommentPopUpComponent, CommPopUpComponent, UploadDialogComponent, ConfirmDialogComponent]
})
export class AppModule {
}
