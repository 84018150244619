import { Component, OnInit } from '@angular/core';
import {MainMapService} from '../services/main-map.service';
import {LoadingService} from '../services/loading.service';
import {TaskService} from '../services/task.service';
import {EditService} from '../services/edit.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ProjectService} from '../services/project.service';

import {TaskPopUpComponent} from '../task-pop-up/task-pop-up.component';

import {switchMap, tap, finalize, flatMap} from 'rxjs/operators';
import {CommService} from '../services/comm.service';
import {CommPopUpComponent} from '../comm-pop-up/comm-pop-up.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-comm',
  templateUrl: './comm.component.html',
  styleUrls: ['./comm.component.css']
})
export class CommComponent implements OnInit {
  displayColumns = ['CommDate', 'Person', 'CommMethod', 'DateResponded'];
  project_id: string;

  constructor(public projectService: ProjectService, private route: ActivatedRoute, private router: Router,
              public mapService: MainMapService, public editService: EditService, public  loadingService: LoadingService,
              public commService: CommService, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.loadingService.show();
    this.route.parent.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.project_id = params.get('id');
        return this.commService.layerIsLoaded.pipe(
          switchMap(() => {
            this.commService.filter.where = `ProjectFK = '${this.project_id}'`;
            return this.commService.getItems().pipe(finalize(() => this.loadingService.hide()));
          })
        );
      })).subscribe();
  }

  openDialog(communications): void {
    if (communications.attributes.GlobalID === 'new') {
      this.commService.create_new_communication(this.project_id).subscribe(new_communications => {
        this.dialog.open(CommPopUpComponent, {
          width: '650px',
          data: {communications: new_communications}
        }).afterClosed().pipe(flatMap(result => {
          console.log('The dialog was closed');
          return this.commService.getItems();
        })).subscribe();
      });
    } else {
      this.dialog.open(CommPopUpComponent, {
        width: '650px',
        data: {communications: communications}
      });
    }
  }
}
