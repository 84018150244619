import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MainMapService} from '../services/main-map.service';
import {LoadingService} from '../services/loading.service';
import {SitesInApeService} from '../services/sites-in-ape.service';
import {EditService} from '../services/edit.service';
import {ShpoService} from '../services/shpo.service';
import {ProjectService} from '../services/project.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TcpService} from '../services/tcp.service';
import {finalize, flatMap, switchMap} from 'rxjs/operators';
import {TcpPopUpComponent} from '../tcp-pop-up/tcp-pop-up.component';
import {TcpComponent} from '../tcp/tcp.component';
import {ShpoPopUpComponent} from '../shpo-pop-up/shpo-pop-up.component';

@Component({
  selector: 'app-shpo',
  templateUrl: './shpo.component.html',
  styleUrls: ['./shpo.component.css']
})
export class ShpoComponent implements OnInit {
  @Input() project_id: Subject<string>;
  displayColumns = ['SHPO_Report', 'Report_Date', 'Findings', 'LevelofEffort'];

  constructor(public projectService: ProjectService, private route: ActivatedRoute, private router: Router,
              public mapService: MainMapService, public editService: EditService, public  loadingService: LoadingService, public shpoService: ShpoService, public tcpService: TcpService, public sitesinapeService: SitesInApeService, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.project_id.pipe(switchMap(project_id => {
      return this.shpoService.layerIsLoaded.pipe(
        switchMap(() => {
          this.shpoService.filter.where = `ProjectFK = '${project_id}'`;
          return this.shpoService.getItems().pipe(finalize(() => this.loadingService.hide()));
        })
      );
    })).subscribe(project_id => console.log(project_id));
  }

  openDialog(shpo): void {
    if (shpo.attributes.GlobalID === 'new') {
      this.shpoService.create_new_shpo(shpo.attributes.ProjectFK).subscribe(new_shpo => {
        this.dialog.open(ShpoPopUpComponent, {
          width: '650px',
          data: {shpo: new_shpo}
        }).afterClosed().pipe(flatMap(result => {
          console.log('The dialog was closed');
          return this.shpoService.getItems();
        })).subscribe();
      });
    } else {
      this.dialog.open(ShpoPopUpComponent, {
        width: '650px',
        data: {shpo: shpo}
      });
    }
  }
}
