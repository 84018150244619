<mat-card class="main-card" style="display: flex; flex-direction: column; height: 100%">
  <mat-table #table [dataSource]="fieldworkService.datasource" style="max-height: 100%; flex:1 1; overflow:auto;">
    <ng-container matColumnDef="Fieldwork_Type">
      <mat-header-cell *matHeaderCellDef>{{fieldworkService.meta.Fieldwork_Type.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.Fieldwork_Type}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Start_Date">
      <mat-header-cell *matHeaderCellDef>{{fieldworkService.meta.Start_Date.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.Start_Date | date:'mediumDate'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Acres_Surveyed">
      <mat-header-cell *matHeaderCellDef>{{fieldworkService.meta.Acres_Surveyed.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.Acres_Surveyed}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Num_Pre_Sites">
      <mat-header-cell *matHeaderCellDef>{{fieldworkService.meta.Num_Pre_Sites.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.Num_Pre_Sites}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Num_Hist_Sites">
      <mat-header-cell *matHeaderCellDef>{{fieldworkService.meta.Num_Hist_Sites.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.Num_Hist_Sites}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Num_Multi_Sites">
      <mat-header-cell *matHeaderCellDef>{{fieldworkService.meta.Num_Multi_Sites.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.Num_Multi_Sites}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
    <mat-row style="cursor: pointer;" *matRowDef="let element; columns: displayColumns" (click)="openDialog(element)" ></mat-row>
  </mat-table>
  <button mat-mini-fab [routerLink]="['new']" (click)="openDialog({attributes: {'GlobalID': 'new', 'ProjectFK': project_id }})" ><mat-icon>add</mat-icon></button>
</mat-card>
