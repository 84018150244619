<mat-card  *ngIf="stipulation | async as p" class="main-card" style="display: flex; flex-direction: column;">
  <form novalidate [formGroup]="featureForm">
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <div>
        <button mat-fab (click)="save(p)" color="accent">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </div>
    <br/><br/>
    <div style="display: flex; flex-direction: column">
<!--      <mat-form-field>-->
<!--        <mat-placeholder>{{stipulationsService.meta.Project_Number.alias}}</mat-placeholder>-->
<!--        <input matInput type="text" name="test" formControlName="Project_Number">-->
<!--      </mat-form-field>-->
      <mat-checkbox formControlName="To_Monitor">{{stipulationsService.meta.To_Monitor.alias}}</mat-checkbox>
      <mat-form-field>
        <mat-placeholder>{{stipulationsService.meta.Monitor.alias}}</mat-placeholder>
        <mat-select formControlName="Monitor">
          <mat-option *ngFor="let option of stipulationsService.meta.Monitor.domain.codedValues"
                      [value]="option.code">{{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{stipulationsService.meta.Monitor_Start.alias}}</mat-placeholder>
        <input matInput [matDatepicker]="MonitorStartPicker" name="Monitor_Start"
               formControlName="Monitor_Start">
        <mat-datepicker-toggle matSuffix [for]="MonitorStartPicker"></mat-datepicker-toggle>
        <mat-datepicker #MonitorStartPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{stipulationsService.meta.Monitor_End.alias}}</mat-placeholder>
        <input matInput [matDatepicker]="Monitor_EndPicker" name="Monitor_End"
               formControlName="Monitor_End">
        <mat-datepicker-toggle matSuffix [for]="Monitor_EndPicker"></mat-datepicker-toggle>
        <mat-datepicker #Monitor_EndPicker></mat-datepicker>
      </mat-form-field>
      <mat-checkbox formControlName="Meet_Place">{{stipulationsService.meta.Meet_Place.alias}}</mat-checkbox>
      <mat-form-field>
        <mat-placeholder>{{stipulationsService.meta.Meet_Place.alias}}</mat-placeholder>
        <input matInput type="text" name="test" formControlName="Meet_Place">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{stipulationsService.meta.Meet_Date.alias}}</mat-placeholder>
        <input matInput [matDatepicker]="Meet_DatePicker" name="Meet_Date"
               formControlName="Meet_Date">
        <mat-datepicker-toggle matSuffix [for]="Meet_DatePicker"></mat-datepicker-toggle>
        <mat-datepicker #Meet_DatePicker></mat-datepicker>
      </mat-form-field>
      <mat-checkbox formControlName="IDP">{{stipulationsService.meta.IDP.alias}}</mat-checkbox>
      <mat-checkbox formControlName="SIte_Visit">{{stipulationsService.meta.SIte_Visit.alias}}</mat-checkbox>
      <mat-form-field>
        <mat-placeholder>{{stipulationsService.meta.Visit_Date.alias}}</mat-placeholder>
        <input matInput [matDatepicker]="Visit_DatePicker" name="Visit_Date"
               formControlName="Visit_Date">
        <mat-datepicker-toggle matSuffix [for]="Visit_DatePicker"></mat-datepicker-toggle>
        <mat-datepicker #Visit_DatePicker></mat-datepicker>
      </mat-form-field>
      <mat-checkbox formControlName="Contractual">{{stipulationsService.meta.Contractual.alias}}</mat-checkbox>
      <mat-form-field>
        <mat-placeholder>{{stipulationsService.meta.Contract_Number.alias}}</mat-placeholder>
        <input matInput type="text" name="Contract_Number" formControlName="Contract_Number">
      </mat-form-field>
      <mat-checkbox formControlName="Other">{{stipulationsService.meta.Other.alias}}</mat-checkbox>
      <mat-form-field>
        <mat-placeholder>{{stipulationsService.meta.Other_Text.alias}}</mat-placeholder>
        <input matInput type="text" name="Other_Text" formControlName="Other_Text">
      </mat-form-field>
      <!--<mat-form-field>-->
        <!--<mat-placeholder>{{stipulationsService.meta.Other_Text.alias}}</mat-placeholder>-->
        <!--<input matInput type="text" name="Other_Text" formControlName="Other_Text">-->
      <!--</mat-form-field>-->
    </div>
  </form>
</mat-card>
