<mat-card *ngIf="review | async as p" class="main-card" style="display: flex; flex-direction: column;">
  <form novalidate [formGroup]="featureForm">
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <div>
        <button mat-fab (click)="save(p)" color="accent">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </div>
    <br/><br/>
    <div style="display: flex; flex-direction: column">

      <!--<mat-form-field>-->
        <!--<mat-placeholder>{{reviewService.meta.Project_Number.alias}}</mat-placeholder>-->
        <!--<input matInput type="text" name="Project_Number" formControlName="Project_Number">-->
      <!--</mat-form-field>-->
      <!--<mat-form-field>-->
        <!--<mat-placeholder>{{reviewService.meta.Project_Name.alias}}</mat-placeholder>-->
        <!--<input matInput type="text" name="Project_Name" formControlName="Project_Name">-->
      <!--</mat-form-field>-->
      <mat-form-field>
        <mat-placeholder>{{reviewService.meta.Ancestral_Territory.alias}}</mat-placeholder>
        <mat-select formControlName="Ancestral_Territory">
          <mat-option *ngFor="let option of reviewService.meta.Ancestral_Territory.domain.codedValues"
                      [value]="option.code">{{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{reviewService.meta.Probability.alias}}</mat-placeholder>
        <mat-select formControlName="Probability">
          <mat-option *ngFor="let option of reviewService.meta.Probability.domain.codedValues"
                      [value]="option.code">{{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{reviewService.meta.Prob_Stipulations.alias}}</mat-placeholder>
        <input matInput type="text" name="Prob_Stipulations" formControlName="Prob_Stipulations">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{reviewService.meta.Historic_Properties.alias}}</mat-placeholder>
        <mat-select formControlName="Historic_Properties">
          <mat-option *ngFor="let option of reviewService.meta.Historic_Properties.domain.codedValues"
                      [value]="option.code">{{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{reviewService.meta.Hist_Prop_Consult.alias}}</mat-placeholder>
        <input matInput type="text" name="Hist_Prop_Consult" formControlName="Hist_Prop_Consult">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{reviewService.meta.Methodolgy.alias}}</mat-placeholder>
        <mat-select formControlName="Methodolgy">
          <mat-option *ngFor="let option of reviewService.meta.Methodolgy.domain.codedValues"
                      [value]="option.code">{{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{reviewService.meta.Meth_Consult.alias}}</mat-placeholder>
        <input matInput type="text" name="Meth_Consult" formControlName="Meth_Consult">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{reviewService.meta.Review_Date.alias}}</mat-placeholder>
        <input matInput [matDatepicker]="ReviewDatePicker" name="Review_Date"
               formControlName="Review_Date">
        <mat-datepicker-toggle matSuffix [for]="ReviewDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #ReviewDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{reviewService.meta.Response_Date.alias}}</mat-placeholder>
        <input matInput [matDatepicker]="ResponseDatePicker" name="Response_Date"
               formControlName="Response_Date">
        <mat-datepicker-toggle matSuffix [for]="ResponseDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #ResponseDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{reviewService.meta.Response_Method.alias}}</mat-placeholder>
        <mat-select formControlName="Response_Method">
          <mat-option *ngFor="let option of reviewService.meta.Response_Method.domain.codedValues"
                      [value]="option.code">{{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-placeholder>{{reviewResponseLuService.meta.Review_Response.alias}}</mat-placeholder>
        <mat-select formControlName="Review_Response" multiple>
          <mat-option *ngFor="let option of reviewResponseLuService.meta.Review_Response.domain.codedValues" [value]="option.code">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-placeholder>{{reviewService.meta.Res_Comment.alias}}</mat-placeholder>
        <input matInput type="text" name="Res_Comment" formControlName="Res_Comment">
      </mat-form-field>
    </div>
  </form>
</mat-card>
