<h1 mat-dialog-title>{{data.task.attributes.Task}}</h1>
<div mat-dialog-content>
  <form novalidate [formGroup]="taskForm">
    <div style="display: flex; flex-direction: column; justify-content: space-between;">
      <mat-form-field>
        <mat-placeholder>{{taskService.meta.Task.alias}}</mat-placeholder>
        <input matInput type ="text" name="Task" formControlName="Task">
      </mat-form-field>
      <!--<mat-form-field>-->
        <!--<mat-placeholder>{{taskService.meta.Project_Number.alias}}</mat-placeholder>-->
        <!--<input matInput type="text" name="Project Number" formControlName="Project_Number">-->
      <!--</mat-form-field>-->
      <mat-form-field>
        <mat-placeholder>{{taskService.meta.Status.alias}}</mat-placeholder>
        <input matInput type="text" name="Status" formControlName="Status">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{taskService.meta.Cost.alias}}</mat-placeholder>
        <input matInput ttype="number" step="any" name="Cost" formControlName="Cost">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{taskService.meta.Description.alias}}</mat-placeholder>
        <input matInput type="text" name="Description" formControlName="Description">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{taskService.meta.Start_Date.alias}}</mat-placeholder>
        <input matInput [matDatepicker]="StartDatePicker" name="Start Date" formControlName="Start_Date">
        <mat-datepicker-toggle matSuffix [for]="StartDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #StartDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{taskService.meta.Due_Date.alias}}</mat-placeholder>
        <input matInput [matDatepicker]="DueDatePicker" name="Due Date" formControlName="Due_Date">
        <mat-datepicker-toggle matSuffix [for]="DueDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #DueDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{taskService.meta.Attachments.alias}}</mat-placeholder>
        <input matInput type="text" name="Attachments" formControlName="Attachments">
      </mat-form-field>
      <!--<mat-form-field>-->
        <!--<mat-placeholder>{{taskService.meta.GlobalID.alias}}</mat-placeholder>-->
        <!--<input matInput type="text" name="Global ID" formControlName="GlobalID">-->
      <!--</mat-form-field>-->
      <!--<mat-form-field>-->
        <!--<mat-placeholder>{{taskService.meta.ProjectFK.alias}}</mat-placeholder>-->
        <!--<input matInput type="text" name="Global ID" formControlName="ProjectFK">-->
      <!--</mat-form-field>-->
      <!--<mat-form-field>-->
        <!--<mat-placeholder>{{taskService.meta.OBJECTID.alias}}</mat-placeholder>-->
        <!--<input matInput type="text" name="Object ID" formControlName="OBJECTID">-->
      <!--</mat-form-field>-->
      <mat-form-field>
        <mat-placeholder>{{taskService.meta.Assigned_To.alias}}</mat-placeholder>
        <input matInput type="text" name="Assigned To" formControlName="Assigned_To">
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()" >Cancel</button>
  <button mat-button (click)="save()" cdkFocusInitial>Save</button>
</div>
