import {iif as observableIif, forkJoin as observableForkJoin, BehaviorSubject, Observable, Subject} from 'rxjs';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Project, ProjectService} from '../services/project.service';
import {EditService} from '../services/edit.service';
import {delay, finalize, map, switchMap, takeWhile, tap, timeout} from 'rxjs/operators';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {MainMapService} from '../services/main-map.service';


import {LoadingService} from '../services/loading.service';
import {ProjectActionsLU, ProjectActionsLuService} from '../services/project-actions-lu.service';
import {LandOwnershipLU, LandOwnershipLuService} from '../services/land-ownership-lu.service';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.css']
})

export class ProjectFormComponent implements OnInit, OnDestroy {
  project: Subject<any>;
  editObs;
  actions: Subject<ProjectActionsLU> = new Subject<ProjectActionsLU>();
  owner: Subject<LandOwnershipLU> = new Subject<LandOwnershipLU>();
  mapMode = 'edit';
  adding;
  featureForm = new FormGroup({
    ProjectNumber: new FormControl(),
    Project_Name: new FormControl(),
    Primary_Reviewer: new FormControl(),
    Priority: new FormControl(),
    Review_Status: new FormControl(),
    Date_Received: new FormControl(),
    Project_Start_Date: new FormControl(),
    Project_Description: new FormControl(),
    Proponent: new FormControl(),
    Proponent_Number: new FormControl(),
    Section_106: new FormControl(),
    Lead_Agency: new FormControl(),
    Agency_or_TCNS_Number: new FormControl(),
    Other_Agency: new FormControl(),
    Other_Agency_Number: new FormControl(),
    Project_Actions: new FormControl(),
    Expected_Ground_Disturbance: new FormControl(),
    // Additional_Review_Needed: new FormControl(),
    // Programs_for_Review: new FormControl(),
    // Additional_Reviewers: new FormControl(),
    Contact_Person: new FormControl(),
    Contact_Info: new FormControl(),
    Contract: new FormControl(),
    Budget: new FormControl(),
    Location: new FormControl(),
    Land_Ownership: new FormControl(),
    Comments: new FormControl(),
    Category: new FormControl(),
    GlobalID: new FormControl(),
    OBJECTID: new FormControl(),
    SHPO_Permit: new FormControl(),
    notification: new FormControl(),
  });

  constructor(public projectService: ProjectService, private route: ActivatedRoute, private router: Router,
              public mapService: MainMapService, public editService: EditService,
              public loadingService: LoadingService, public projectActionsLuService: ProjectActionsLuService,
              public landOwnershipLuService: LandOwnershipLuService, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.loadingService.show();
    this.project = new BehaviorSubject(null);
    this.route.parent.paramMap.pipe(
      switchMap((params: ParamMap) => {
        if (params.get('id') === 'new') {
          this.adding = true;
          return this.projectService.create_new_project();
        } else return this.projectService.selectFeature(params.get('id'), null);
      }),
      map(project => {
        if (this.editObs !== undefined) {
          this.editObs.unsubscribe();
          this.mapMode = '';
        }
        this.project.next(project);
        if (project.attributes.GlobalID !== 'new') {
          this.adding = false;
          const project_action_obs = this.projectActionsLuService.layerIsLoaded.pipe(switchMap(() => {
            this.projectActionsLuService.filter.where = `projectsid_fk = '${project.attributes.GlobalID}'`;
            return this.projectActionsLuService.query().pipe(tap(results => {
              this.actions = results;
              project.attributes.Project_Actions = results.map(r => r.attributes.Project_Actions);
            }));
          }));

          const land_ownership_obs = this.landOwnershipLuService.layerIsLoaded.pipe(switchMap(() => {
            this.landOwnershipLuService.filter.where = `project_fk = '${project.attributes.GlobalID}'`;
            return this.landOwnershipLuService.query().pipe(tap(results => {
              this.owner = results;
              project.attributes.Land_Ownership = results.map(r => r.attributes.Land_Ownership);
            }));
          }));
          observableForkJoin([project_action_obs, land_ownership_obs]).subscribe(() => this.featureForm.patchValue(project.attributes));
        } else this.featureForm.patchValue(project.attributes);

        // if (project.attributes.Project_Actions) project.attributes.Project_Actions = project.attributes.Project_Actions.split(',')
        // this.featureForm.patchValue(project.attributes);
        if (project.attributes.GlobalID !== 'new' && project.geometry !== null && project.geometry.rings.length > 0) {
          this.mapService.center(project.geometry);
          this.editPolygon();
        } else {
          this.mapService.addGraphic(project);
          this.addPolygon();

        }
      }),
    ).subscribe(() => {
      // this.clicked = false;
      this.loadingService.hide();
    });
  }

  addPolygon() {
    this.mapMode = 'draw';
    if (this.editObs !== undefined) this.editObs.unsubscribe();
    this.project.pipe(
      takeWhile(() => this.mapMode === 'draw')
    ).subscribe(project => {
      this.editObs = this.editService.add_polygon().subscribe(geometry => {
        project.geometry.addRing(geometry.rings[0]);
        project.setGeometry(project.geometry);
        this.mapService.redrawGraphics();
      });
    });
  }

  editPolygon() {
    this.mapMode = 'edit';
    if (this.editObs !== undefined) this.editObs.unsubscribe();
    this.project.subscribe(project => {
      this.editObs = this.editService.edit_verticies(project).subscribe(feature => {
        // this.project.next(feature);
      });
    });
  }

  ngOnDestroy() {
    this.editObs.unsubscribe();
    this.mapService.clearGraphics();
  }

  save(project) {
    console.log('single');
    project.attributes = this.featureForm.value;
    let project_actions = project.attributes.Project_Actions;
    delete project.attributes.Project_Actions;
    let land_ownership = project.attributes.Land_Ownership;
    delete project.attributes.Land_Ownership;

    // query max
    // increment
    // [{"statisticType":"max","onStatisticField":"ProjectNumber", "outStatisticFieldName":"ProjectNumMAX"}]
    this.projectService.layerIsLoaded.subscribe(() => {
      this.projectService.queryMax().subscribe(projectMax_results => {
        if (project.attributes.ProjectNumber === null) {
          project.attributes.ProjectNumber = Number(projectMax_results[0].attributes.ProjectNumMAX) + 1;
        }
        observableIif(() => project.attributes.GlobalID === 'new',
          this.projectService.addFeature(project).pipe(
            tap(results => {
              this.editObs.unsubscribe();
              this.mapService.clearGraphics();
              console.log('save add');
              this.router.navigate(['/edit', results[0].globalId]);
              delay(2000); // yeah, i know, total kludge.
              return results;
            })
          ),
          this.projectService.updateFeature(project))
          .subscribe(results => {
            console.log('lookups');
            // delete all related multiselect values
            this.projectActionsLuService.layerIsLoaded.subscribe(() => {
              this.projectActionsLuService.filter.where = `projectsid_fk = '${results[0].globalId}'`;
              this.projectActionsLuService.query().subscribe(projectActions_results => {
                projectActions_results.forEach((element) => {
                  this.projectActionsLuService.deleteFeature(element).subscribe();
                });
                if (project_actions instanceof Array) {
                  project_actions.forEach((element) => {
                    this.projectActionsLuService.create_new_action(results[0].globalId, element).subscribe();
                  });
                }
              });
            });

            this.landOwnershipLuService.layerIsLoaded.subscribe(() => {
              this.landOwnershipLuService.filter.where = `project_fk = '${results[0].globalId}'`;
              this.landOwnershipLuService.query().subscribe(landOwner_results => {
                landOwner_results.forEach((element) => {
                  this.landOwnershipLuService.deleteFeature(element).subscribe();
                });
                if (land_ownership instanceof Array) {
                  land_ownership.forEach((element) => {
                    this.landOwnershipLuService.create_new_action(results[0].globalId, element).subscribe();
                  });
                }
              });
            });
          });
      });
    });
    // }

    // this.featureForm.patchValue(project.attributes);
  }

  delete_feature(project) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {msg: 'Are you sure you want to delete Project ' + this.featureForm.value.ProjectNumber + ' ?', positiveText: 'Yes', negativeText: 'No'}
    });
    dialog.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        console.log('delete');
        project.attributes = this.featureForm.value;
        let project_actions = project.attributes.Project_Actions;
        delete project.attributes.Project_Actions;
        let land_ownership = project.attributes.Land_Ownership;
        delete project.attributes.Land_Ownership;

        this.projectService.deleteFeature(project)
          .subscribe(results => {
              console.log('lookups');
              // delete all related multiselect values
              this.projectActionsLuService.layerIsLoaded.subscribe(() => {
                this.projectActionsLuService.filter.where = `projectsid_fk = '${results[0].globalId}'`;
                this.projectActionsLuService.query().subscribe(projectActions_results => {
                  projectActions_results.forEach((element) => {
                    this.projectActionsLuService.deleteFeature(element).subscribe();
                  });
                });
              });

              this.landOwnershipLuService.layerIsLoaded.subscribe(() => {
                this.landOwnershipLuService.filter.where = `project_fk = '${results[0].globalId}'`;
                this.landOwnershipLuService.query().subscribe(landOwner_results => {
                  landOwner_results.forEach((element) => {
                    this.landOwnershipLuService.deleteFeature(element).subscribe();
                  });
                });
              });
              this.editObs.unsubscribe();
              this.mapService.clearGraphics();
              this.router.navigate(['']);
            }
          );

      }
    });
  }
}
