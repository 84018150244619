import {loadModules} from 'esri-loader';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {ConfigService} from './config.service';

export class ArcPrintingService {
  gp;

  constructor(url: string, public snackBar: MatSnackBar) {
    const vm = this;
    const config = new ConfigService();
    loadModules(['esri/tasks/Geoprocessor']).then(([Geoprocessor]) => {
      vm.gp = new Geoprocessor(`${config.rest_setting.report}${url}`);
    });
  }

  openSnackBar(message: string, action: string) {
    const vm = this;
    vm.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  public submit(params) {
    const vm = this;
    return new Observable(obs => {
      vm.gp.submitJob(params, function (e) {
        if (e.jobStatus === 'esriJobFailed') {
          vm.openSnackBar(`Error: ` + e.message, '');
          obs.complete();
        } else {
          vm.gp.getResultData(e.jobId, 'ReportName', function (result) {
            window.open(result.value.url);
            obs.complete();
          });
        }
      }
      , null, function (err) {
        vm.openSnackBar(err.toString() + ' ' + err.details[0], '');
        obs.error(err);
      }
      );
    });
  }
}
