<h1 mat-dialog-title>Comment
</h1>
<div mat-dialog-content>
  <form novalidate [formGroup]="featureForm">
    <div style="display: flex; flex-direction: column; justify-content: space-between;">



      <!--<mat-form-field>-->
        <!--<mat-placeholder>{{commentService.meta.ProjectFK.alias}}</mat-placeholder>-->
        <!--<input matInput type="text" name="ProjectFK" formControlName="ProjectFK">-->
      <!--</mat-form-field>-->
    </div>
      <div style="display: flex; flex-direction: column; justify-content: space-between;">
      <mat-form-field>
        <mat-placeholder>{{commentService.meta.Comments.alias}}</mat-placeholder>
        <input matInput type="text" name="Comments" formControlName="Comments">
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="save()" cdkFocusInitial>Save</button>
</div>
