<!--<nav mat-tab-nav-bar *ngIf="route.paramMap | async as params">-->
  <!--<a mat-tab-link routerLink="project"-->
     <!--routerLinkActive #project="routerLinkActive"-->
     <!--[active]="project.isActive">-->
    <!--Project Information-->
  <!--</a>-->
  <!--<a mat-tab-link routerLink="review"-->
     <!--routerLinkActive #related="routerLinkActive"-->
     <!--[active]="related.isActive"-->
     <!--*ngIf="params.get('id') !== 'new'">-->
    <!--Review-->
  <!--</a>-->
  <!--<a mat-tab-link routerLink="cultural resources"-->
     <!--routerLinkActive #related="routerLinkActive"-->
     <!--[active]="related.isActive"-->
     <!--*ngIf="params.get('id') !== 'new'">-->
    <!--Cultural Resources-->
  <!--</a>-->
  <!--<a mat-tab-link routerLink="stipulations"-->
     <!--routerLinkActive #related="routerLinkActive"-->
     <!--[active]="related.isActive"-->
     <!--*ngIf="params.get('id') !== 'new'">-->
    <!--Stipulations-->
  <!--</a>-->
  <!--<a mat-tab-link routerLink="assigned"-->
     <!--routerLinkActive #related="routerLinkActive"-->
     <!--[active]="related.isActive"-->
     <!--*ngIf="params.get('id') !== 'new'">-->
    <!--Assigned Tasks-->
  <!--</a>-->
  <!--<a mat-tab-link routerLink="fieldwork"-->
     <!--routerLinkActive #related="routerLinkActive"-->
     <!--[active]="related.isActive"-->
     <!--*ngIf="params.get('id') !== 'new'">-->
    <!--Fieldwork-->
  <!--</a>-->


  <!--<mat-tab label="Something else" routerLink="related">-->
    <!--<app-related-table-form></app-related-table-form>-->
  <!--</mat-tab>-->
<mat-divider></mat-divider>
<mat-toolbar color='warn'>{{data.currentMessage |async}}</mat-toolbar>
<nav mat-tab-nav-bar *ngIf="route.paramMap | async as params">
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <a mat-tab-link routerLink="project"
           routerLinkActive #project="routerLinkActive"
           [active]="project.isActive">
          Project Information
        </a>
        <a mat-tab-link routerLink="review"
           routerLinkActive #related="routerLinkActive"
           [active]="related.isActive"
           *ngIf="params.get('id') !== 'new'">
          Review
        </a>
        <a mat-tab-link routerLink="cultural resources"
           routerLinkActive #related="routerLinkActive"
           [active]="related.isActive"
           *ngIf="params.get('id') !== 'new'">
          Cultural Resources
        </a>
        <a mat-tab-link routerLink="stipulations"
           routerLinkActive #related="routerLinkActive"
           [active]="related.isActive"
           *ngIf="params.get('id') !== 'new'">
          Stipulations
        </a>
        <a mat-tab-link routerLink="assigned"
           routerLinkActive #related="routerLinkActive"
           [active]="related.isActive"
           *ngIf="params.get('id') !== 'new'">
          Assigned Tasks
        </a>
        <a mat-tab-link routerLink="fieldwork"
           routerLinkActive #related="routerLinkActive"
           [active]="related.isActive"
           *ngIf="params.get('id') !== 'new'">
          Fieldwork
        </a>
        <a mat-tab-link routerLink="comments"
           routerLinkActive #related="routerLinkActive"
           [active]="related.isActive"
           *ngIf="params.get('id') !== 'new'">
          Comments
        </a>
        <a mat-tab-link routerLink="communications"
               routerLinkActive #related="routerLinkActive"
               [active]="related.isActive"
               *ngIf="params.get('id') !== 'new'">
        Communications Log
      </a>
        <a mat-tab-link routerLink="attachments"
           routerLinkActive #related="routerLinkActive"
           [active]="related.isActive"
           *ngIf="params.get('id') !== 'new'">
          Attachments
        </a>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</nav>
<!--<mat-progress-bar mode="indeterminate" *ngIf="loadingService.loading | async"-->
<!--                  style="margin-top: -3px; height: 2px;"></mat-progress-bar>-->
<router-outlet></router-outlet>

