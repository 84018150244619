import { loadModules } from 'esri-loader';
import { ConfigService } from './config.service';
import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
var MainMapService = /** @class */ (function () {
    function MainMapService() {
        this.mapObs = new ReplaySubject();
        this.basemaps = new BehaviorSubject([]);
    }
    MainMapService.prototype.init = function (elementId) {
        var config = new ConfigService();
        var map, vm = this;
        var usaTopo = {
            baseMapLayers: [{ url: 'https://services.arcgisonline.com/ArcGIS/rest/services/USA_Topo_Maps/MapServer' }],
            thumbnailUrl: 'http://www.arcgis.com/sharing/rest/content/items/931d892ac7a843d7ba29d085e0433465/info/thumbnail/usa_topo.jpg',
            title: 'USA Topo'
        };
        // vm.mapObs = new ReplaySubject<any>();
        loadModules(['esri/map', 'esri/basemaps'], config.jsapi_config)
            .then(function (_a) {
            var Map = _a[0], basemaps = _a[1];
            vm.basemaps.next([basemaps.streets,
                basemaps.satellite,
                basemaps.terrain,
                basemaps.topo,
                basemaps.oceans,
                basemaps.gray,
                usaTopo]);
            map = new Map(elementId, {
                center: environment.MapCenter,
                zoom: environment.MapZoom,
                basemap: 'topo',
                autoResize: true,
                slider: true
            });
            map.on('load', function () {
                vm.mapObs.next(map);
            });
        });
        return this.mapObs;
    };
    MainMapService.prototype.addLayer = function (layer) {
        this.mapObs.subscribe(function (map) {
            map.addLayer(layer);
        });
    };
    MainMapService.prototype.center = function (geometry) {
        this.mapObs.subscribe(function (map) {
            setTimeout(function () {
                if (geometry.type === 'point')
                    map.centerAt(geometry);
                else if (geometry.type === 'polygon')
                    map.setExtent(geometry.getExtent(), true);
                // map.setZoom(map.getZoom() - 1);
            }, 500);
        });
    };
    MainMapService.prototype.zoomIn = function () {
        this.mapObs.subscribe(function (map) { return map.setZoom(map.getZoom() + 1); });
    };
    MainMapService.prototype.zoomOut = function () {
        this.mapObs.subscribe(function (map) { return map.setZoom(map.getZoom() - 1); });
    };
    MainMapService.prototype.addGraphic = function (graphic) {
        this.mapObs.subscribe(function (map) { return map.graphics.add(graphic); });
    };
    MainMapService.prototype.redrawGraphics = function () {
        this.mapObs.subscribe(function (map) { return map.graphics.redraw(); });
    };
    MainMapService.prototype.clearGraphics = function () {
        this.mapObs.subscribe(function (map) { return map.graphics.clear(); });
    };
    MainMapService.prototype.switchBasemap = function (basemap) {
        this.mapObs.subscribe(function (map) { return map.setBasemap(basemap); });
    };
    return MainMapService;
}());
export { MainMapService };
