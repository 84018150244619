import {Injectable} from '@angular/core';
import {loadModules} from 'esri-loader';
import {Observable} from 'rxjs';
import {ConfigService} from './config.service';
import {MainMapService} from './main-map.service';
import {finalize} from 'rxjs/operators';

@Injectable()
export class EditService {
  edit;
  draw;
  MOVE;
  EDIT_VERTICES;
  POLYGON;
  ready: Promise<any>;

  constructor(private config: ConfigService, private mapService: MainMapService) {
    const vm = this;
    vm.ready = new Promise((resolve, reject) => {
      vm.mapService.mapObs.subscribe(map => {
        loadModules(['esri/toolbars/edit', 'esri/toolbars/draw']).then(([Edit, Draw]) => {
          vm.edit = new Edit(map);
          vm.draw = new Draw(map);
          vm.EDIT_VERTICES = Edit.EDIT_VERTICES;
          vm.POLYGON = Draw.POLYGON;
          resolve();
        });
      });
    });
  }

  // move(feature): Observable<any> {
  //   return Observable.create(obs => {
  //     this.ready.then(() => {
  //       edit.on('graphic-move-stop', function (e) {
  //         obs.next(e.graphic);
  //       });
  //       edit.activate(Edit.MOVE, feature);
  //     });
  //   }).pipe(
  //     finalize(() => edit.deactivate())
  //   );
  // }

  edit_verticies(feature) {
    const vm = this;
    return Observable.create(obs => {
      this.ready.then(function () {
        vm.edit.activate(vm.EDIT_VERTICES, feature);
      });
    }).pipe(
      finalize(() => {
        if (vm.edit) vm.edit.deactivate();
      })
    );
  }

  add_polygon() {
    const vm = this;
    return Observable.create(obs => {
      this.ready.then(() => {
        vm.draw.on('draw-complete', function (e) {
          obs.next(e.geometry);
        });
        vm.draw.activate(vm.POLYGON);
      });
    }).pipe(
      finalize(() => {
        if (vm.draw) vm.draw.deactivate();
      })
    );
  }

  stop() {
    let vm = this;
    this.ready.then(() => vm.edit.deactivate());
  }
}
