/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./confirm-dialog.component";
import * as i8 from "@angular/material/dialog";
var styles_ConfirmDialogComponent = [i0.styles];
var RenderType_ConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
export function View_ConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["style", "display: flex; flex-direction: column; justify-content: space-around"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["style", "display: flex; flex-direction: row; justify-content: flex-end;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["color", "accent"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(6, 0, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["color", "accent"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dialogRef.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(9, 0, ["", ""]))], function (_ck, _v) { var currVal_3 = "accent"; _ck(_v, 5, 0, currVal_3); var currVal_7 = "accent"; _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.msg; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).disabled || null); var currVal_2 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = _co.data.negativeText; _ck(_v, 6, 0, currVal_4); var currVal_5 = (i1.ɵnov(_v, 8).disabled || null); var currVal_6 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_5, currVal_6); var currVal_8 = _co.data.positiveText; _ck(_v, 9, 0, currVal_8); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-dialog", [], null, null, null, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.ConfirmDialogComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmDialogComponentNgFactory = i1.ɵccf("app-confirm-dialog", i7.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
