<mat-card class="main-card" style="display: flex; flex-direction: column; flex: 1">
  <mat-table *ngIf="shpoService.layerIsLoaded | async" #table [dataSource]="shpoService.datasource" style="max-height: 100%; flex:1 1; overflow:auto;">
    <ng-container matColumnDef="SHPO_Report">
      <mat-header-cell *matHeaderCellDef>{{shpoService.meta.SHPO_Report.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.SHPO_Report}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Report_Date">
      <mat-header-cell *matHeaderCellDef>{{shpoService.meta.Report_Date.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.Report_Date | date: mediumDate}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Findings">
      <mat-header-cell *matHeaderCellDef>{{shpoService.meta.Findings.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.Findings}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="LevelofEffort">
      <mat-header-cell *matHeaderCellDef>{{shpoService.meta.LevelofEffort.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.LevelofEffort}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
    <mat-row style="cursor: pointer;" *matRowDef="let element; columns: displayColumns" (click)="openDialog(element)" ></mat-row>
  </mat-table>
  <button mat-mini-fab *ngIf="project_id | async as p_id" [routerLink]="['new']" (click)="openDialog({attributes: {'GlobalID': 'new', 'ProjectFK': p_id }})" ><mat-icon>add</mat-icon></button>
</mat-card>
