<h1 mat-dialog-title>Sites in APE
</h1>
<div mat-dialog-content>
  <form novalidate [formGroup]="featureForm">
    <div style="display: flex; flex-direction: column; justify-content: space-between;">
      <mat-form-field>
        <mat-placeholder>{{sitesinapeService.meta.Site_Number.alias}}</mat-placeholder>
        <input matInput type="text" name="Site Number" formControlName="Site_Number">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{sitesinapeService.meta.NRPH.alias}}</mat-placeholder>
        <input matInput type="text" name="NRPH" formControlName="NRPH">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{sitesinapeService.meta.Site_Type.alias}}</mat-placeholder>
        <input matInput type="text" name="Site Type" formControlName="Site_Type">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{sitesinapeService.meta.Site_Description.alias}}</mat-placeholder>
        <input matInput type="text" name="Site Description" formControlName="Site_Description">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{sitesinapeService.meta.Effect.alias}}</mat-placeholder>
        <input matInput type="text" name="Effect" formControlName="Effect">
      </mat-form-field>
      <mat-form-field>
        <mat-placeholder>{{sitesinapeService.meta.Effect_Comments.alias}}</mat-placeholder>
        <input matInput type="text" name="Effect Comments" formControlName="Effect_Comments">
      </mat-form-field>
      <!--<mat-form-field>-->
        <!--<mat-placeholder>{{sitesinapeService.meta.ProjectFK.alias}}</mat-placeholder>-->
        <!--<input matInput type="text" name="ProjectFk" formControlName="ProjectFK">-->
      <!--</mat-form-field>-->
      <!--<mat-form-field>-->
        <!--<mat-placeholder>{{sitesinapeService.meta.GlobalID.alias}}</mat-placeholder>-->
        <!--<input matInput type="text" name="GlobalID" formControlName="GlobalID">-->
      <!--</mat-form-field>-->
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="saveApe()" cdkFocusInitial>Save</button>
</div>
