import {Injectable} from '@angular/core';
import {loadModules} from 'esri-loader';
import {ConfigService} from './config.service';
import {finalize} from 'rxjs/operators';
import {ReplaySubject, BehaviorSubject} from 'rxjs';
import {getInitializer} from 'codelyzer/util/astQuery';
import { NgStyle } from '@angular/common';
import { environment } from '../../environments/environment';




export class MainMapService {
  mapObs: ReplaySubject<any> = new ReplaySubject<any>();
  basemaps: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor() {
  }

  init(elementId) {
    const config = new ConfigService();
    let map, vm = this;
     const usaTopo = {
      baseMapLayers: [{url: 'https://services.arcgisonline.com/ArcGIS/rest/services/USA_Topo_Maps/MapServer'}],
      thumbnailUrl: 'http://www.arcgis.com/sharing/rest/content/items/931d892ac7a843d7ba29d085e0433465/info/thumbnail/usa_topo.jpg',
      title: 'USA Topo'
     };
    // vm.mapObs = new ReplaySubject<any>();
    loadModules(['esri/map', 'esri/basemaps'], config.jsapi_config)
      .then(([Map, basemaps]) => {
        vm.basemaps.next(
          [basemaps.streets,
                basemaps.satellite,
                basemaps.terrain,
                basemaps.topo,
                basemaps.oceans,
                basemaps.gray,
               usaTopo]);
        map = new Map(elementId, {
          center: environment.MapCenter,
          zoom: environment.MapZoom,
          basemap: 'topo',
          autoResize: true,
          slider: true
        });
        map.on('load', function () {
          vm.mapObs.next(map);
        });
      });
    return this.mapObs;
  }

  addLayer(layer) {
    this.mapObs.subscribe(map => {
      map.addLayer(layer);
    });
  }

  center(geometry) {
    this.mapObs.subscribe(map => {
      setTimeout(() => {
        if (geometry.type === 'point') map.centerAt(geometry);
        else if (geometry.type === 'polygon') map.setExtent(geometry.getExtent(), true);

        // map.setZoom(map.getZoom() - 1);
      }, 500);
    });
  }

  zoomIn() {
    this.mapObs.subscribe(map => map.setZoom(map.getZoom() + 1));
  }

  zoomOut() {
    this.mapObs.subscribe(map => map.setZoom(map.getZoom() - 1));
  }

  addGraphic(graphic) {
    this.mapObs.subscribe(map => map.graphics.add(graphic));
  }

  redrawGraphics() {
    this.mapObs.subscribe(map => map.graphics.redraw());
  }

  clearGraphics() {
    this.mapObs.subscribe(map => map.graphics.clear());
  }
   switchBasemap(basemap) {
    this.mapObs.subscribe(map => map.setBasemap(basemap));
  }
}
