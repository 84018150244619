import {Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {throttleTime} from 'rxjs/operators';

// based on https://stackoverflow.com/questions/51390476/how-to-prevent-double-click-in-angular
@Directive({
  selector: '[appThrottleClick]'
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
  @Output()
  throttledClick = new EventEmitter();
  @Input()
  throttleTime = 500;

  private clicker = new Subject();
  private subscription: Subscription;

  constructor() {
  }

  ngOnInit() {
    this.subscription = this.clicker.pipe(
      throttleTime(this.throttleTime)
    ).subscribe(e => this.throttledClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicker.next(event);
  }
}
