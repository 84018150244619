import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ProjectService} from '../services/project.service';
import {CommService} from '../services/comm.service';
import {LoadingService} from '../services/loading.service';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-comm-pop-up',
  templateUrl: './comm-pop-up.component.html',
  styleUrls: ['./comm-pop-up.component.css']
})
export class CommPopUpComponent implements OnInit {
  project: Subject<any>;
  featureForm = new FormGroup({
    CommDate: new FormControl(),
    Person: new FormControl(),
    CommMethod: new FormControl(),
    Communication: new FormControl(),
    DateResponded: new FormControl(),
    GlobalID: new FormControl(),
    ProjectFK: new FormControl(),
    OBJECTID: new FormControl()
  });
  constructor(public dialogRef: MatDialogRef<CommPopUpComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public commService: CommService,
              public projectService: ProjectService, public  loadingService: LoadingService) {}

  ngOnInit() {
    // this.loadingService.show();
    this.featureForm.patchValue(this.data.communications.attributes);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  save() {
    this.data.communications.attributes = this.featureForm.value;
    if (this.data.communications.attributes.GlobalID === 'new') this.commService.addFeature(this.data.communications).subscribe(() => this.dialogRef.close());
    else this.commService.updateFeature(this.data.communications).subscribe(() => this.dialogRef.close());
  }
}
