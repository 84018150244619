import {Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ProjectService} from '../services/project.service';
import {EditService} from '../services/edit.service';
import { map, switchMap } from 'rxjs/operators';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {MainMapService} from '../services/main-map.service';
import {BehaviorSubject, Subject} from 'rxjs';


import {LoadingService} from '../services/loading.service';
import {ConfigService} from '../services/config.service';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';


export interface UploadDialogData {
  objectID: number;
  maxAttach: number;
  attached: number;
}

@Component({
  selector: 'app-attachment-form',
  templateUrl: './attachment-form.component.html',
  styleUrls: ['./attachment-form.component.css']
})
export class AttachmentFormComponent implements OnInit {
  objectID: number;

  project: Subject<any>;

  attachments: any;
  maxAttachments: number;
  featureForm = new FormGroup({
    ProjectNumber: new FormControl(),
    Project_Name: new FormControl(),
    Primary_Reviewer: new FormControl(),
    Priority: new FormControl(),
    Review_Status: new FormControl(),
    Date_Received: new FormControl(),
    Project_Start_Date: new FormControl(),
    Project_Description: new FormControl(),
    Proponent: new FormControl(),
    Proponent_Number: new FormControl(),
    Section_106: new FormControl(),
    Lead_Agency: new FormControl(),
    Agency_or_TCNS_Number: new FormControl(),
    Other_Agency: new FormControl(),
    Other_Agency_Number: new FormControl(),
    Project_Actions: new FormControl(),
    Expected_Ground_Disturbance: new FormControl(),
    Contact_Person: new FormControl(),
    Contact_Info: new FormControl(),
    Contract: new FormControl(),
    Budget: new FormControl(),
    Location: new FormControl(),
    Land_Ownership: new FormControl(),
    Comments: new FormControl(),
    Category: new FormControl(),
    GlobalID: new FormControl(),
    OBJECTID: new FormControl(),
    SHPO_Permit: new FormControl(),
    notification: new FormControl(),
  });

  constructor(public projectService: ProjectService, private route: ActivatedRoute, private router: Router,
              public mapService: MainMapService, public editService: EditService,
              public  loadingService: LoadingService, private dialog: MatDialog) {
  }

  ngOnInit() {
    const config = new ConfigService();
    this.maxAttachments = config.maxAttachments;
    this.loadingService.show();
    this.project = new BehaviorSubject(null);
    this.route.parent.paramMap.pipe(
      switchMap((params: ParamMap) => {
        return this.projectService.selectFeature(params.get('id'), null);
      }),
      map(project => {
        this.project.next(project);
        this.objectID = project.attributes.OBJECTID;
        this.projectService.getAttachments(project.attributes.OBJECTID).subscribe(attachments => {
          this.attachments = attachments;
        });
        this.featureForm.patchValue(project.attributes);
      }),
    ).subscribe(() => this.loadingService.hide());
  }

  showDeleteDialog(attachment, $event) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {msg: 'Are you sure you want to delete ' + attachment.name + ' ?', positiveText: 'Yes', negativeText: 'No'}
    });
    dialog.afterClosed().subscribe(confirmed => {
      if (confirmed)       this.projectService.deleteAttachments(attachment.objectId, attachment.id).subscribe(() =>
        this.projectService.getAttachments(attachment.objectId).subscribe(attachments => {
          this.attachments = attachments;
        }));
    });
  }

  showUploadDialog(ev) {
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: '550px',
      // height: '205px',
      data: { objectID: this.objectID, maxAttach: this.maxAttachments, attached: this.attachments.length}
     });
    dialogRef.afterClosed().subscribe(result => {
      this.projectService.getAttachments(this.objectID).subscribe(attachments => {
        this.attachments = attachments;
      });
    });
  }

}

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './attachment-dialog.html',
  styleUrls: ['./attachment-form.component.css']
})

export class UploadDialogComponent {
  uploading = false;
  message =  'Please pick a file to upload';
  @ViewChild('fileNameInput')
  myInputVariable: ElementRef;
  constructor(
    public projectService: ProjectService,
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UploadDialogData) {}

  cancel(): void {
    this.dialogRef.close();
  }

  uploadFile () {
    if ( (<HTMLInputElement>document.getElementById('attachmentInput')).value !== '') {
      this.uploading = true;
      this.projectService.uploadAttachments(this.data.objectID, document.getElementById('attachmentForm')).subscribe( () => {
        this.uploading = false;
        this.data.attached += 1;
        this.myInputVariable.nativeElement.value = '';
      });
    }
  }
}
