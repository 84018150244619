import {environment} from '../../environments/environment';

export class ConfigService {
  jsapi_config = {url: 'https://js.arcgis.com/3.23/'};
  // portal_setting_cowcreek = {
  //   url: 'https://arcgis.cowcreek.com',
  //   appId: 'HiiQAKbS9xeeh6at'
  // };
  // portal_setting_inno = {
  //   url: 'https://portal.innovateteam.com/arcgis',
  //   appId: 'Z2OH2A7OhALln1PS'
  // };
  // rest_setting_inno = {
  //   url: 'https://it.innovateteam.com/arcgis/rest/services/CRIS/CRISCCCBUTI_Test/FeatureServer/',
  //   report: 'http://it.innovateteam.com/arcgis/rest/services/CRIS/'
  // };
  // rest_setting_cowcreek = {
  //   url: 'https://tlid.cowcreek.com/arcgis/rest/services/CRISCCBUTI/FeatureServer/',
  //   report: 'https://tlid.cowcreek.com/arcgis/rest/services/'
  // };
  portal_setting = {
    url: environment.Portal_URL,
    appId: environment.Portal_AppID
  };
  rest_setting = {
    url: environment.Rest_URL,
    report: environment.Rest_Report_URL
  };
  maxAttachments = environment.MaxAttach;
}
