<mat-card class="main-card" style="display: flex; flex-direction: column; height: 100%">
  <mat-table #table [dataSource]="taskService.datasource" style="max-height: 100%; flex:1 1; overflow:auto;">
    <ng-container matColumnDef="Task">
      <mat-header-cell *matHeaderCellDef>{{taskService.meta.Task.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.Task}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Start_Date">
      <mat-header-cell *matHeaderCellDef>{{taskService.meta.Start_Date.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.Start_Date | date:'mediumDate'}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Assigned_To">
      <mat-header-cell *matHeaderCellDef>{{taskService.meta.Assigned_To.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.Assigned_To}} </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
    <mat-row style="cursor: pointer;" *matRowDef="let element; columns: displayColumns" (click)="openDialog(element)" ></mat-row>
  </mat-table>
  <button mat-mini-fab [routerLink]="['new']" (click)="openDialog({attributes: {'GlobalID': 'new', 'ProjectFK': project_id }})" ><mat-icon>add</mat-icon></button>
</mat-card>

