var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { loadModules } from 'esri-loader';
// import FeatureLayer = require('esri/layers/FeatureLayer');
import { ConfigService } from './config.service';
import { ReplaySubject, Observable, BehaviorSubject } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { finalize, map } from 'rxjs/operators';
// import SpatialReference = require('esri/SpatialReference');
// import ProjectParameters = require('esri/tasks/ProjectParameters');
// import Point = require('esri/geometry/Point');
// import GeometryService = require('esri/tasks/GeometryService');
// import Query = require('esri/tasks/query');
// import Graphic = require('esri/graphic');
// export interface MyGraphic extends Graphic {
//   coordinates: Point;
// }
//
// export interface MyFeatureSet extends FeatureSet {
//   features: MyGraphic[];
// }
var ArcBaseService = /** @class */ (function () {
    function ArcBaseService(url, snackBar, loadingService) {
        this.snackBar = snackBar;
        this.loadingService = loadingService;
        this.layerIsLoaded = new ReplaySubject();
        this.config = new ConfigService();
        this.count = new BehaviorSubject(0);
        this.current_page = 0;
        this.dataChange = new BehaviorSubject([]);
        var vm = this;
        vm.datasource = new BaseDataSource(vm);
        var config = new ConfigService();
        loadModules(['esri/layers/FeatureLayer', 'esri/tasks/query', 'esri/geometry/Polygon', 'esri/SpatialReference'], config.jsapi_config)
            .then(function (_a) {
            var _FeatureLayer = _a[0], _Query = _a[1], _Polygon = _a[2], _SpatialReference = _a[3];
            vm.FeatureLayer = _FeatureLayer;
            vm.Query = _Query;
            vm.Polygon = _Polygon;
            vm.SpatialReference = _SpatialReference;
            vm.filter = new _Query();
            vm.filter.num = 25;
            vm.filter.start = 0;
            vm.layer = new vm.FeatureLayer("" + config.rest_setting.url + url, {
                outFields: ['*'],
                mode: vm.FeatureLayer.MODE_SNAPSHOT
            });
            vm.layer.on('load', function () {
                vm.meta = vm.prep_fields_meta(vm.layer.fields);
                vm.layerIsLoaded.next(true);
                vm.layerIsLoaded.complete();
            });
        });
    }
    ArcBaseService.prototype.save = function (feature, type, quiet) {
        var _this = this;
        if (quiet === void 0) { quiet = false; }
        var vm = this;
        return new Observable(function (obs) {
            if (feature.geometry !== null && feature.geometry !== undefined && feature.geometry.type === 'polygon' && feature.geometry.isSelfIntersecting()) {
                obs.error('Polygons cannot be self intersecting.');
            }
            else {
                _this.layerIsLoaded.subscribe(function () {
                    // const tempFeature = new graphic({attributes: feature.attributes});
                    // this.projectPoint(feature.coordinates, new sr(3857)).subscribe(point => {
                    //   if (point !== undefined) tempFeature.geometry = point;
                    //   else tempFeature.geometry = feature.geometry;
                    // get geometry to null if its empty so the server does not reject it
                    var features = _this.prepForServer([feature.clone()]);
                    if (type === 'add')
                        _this.layer.applyEdits(features, null, null, function (results) {
                            obs.next(results);
                            if (!quiet)
                                vm.openSnackBar('Added!', '');
                        }, function (e) {
                            var details = e.details !== undefined ? e.details[0] : '';
                            vm.openSnackBar(e.toString() + " " + details, '');
                            obs.error(e);
                        });
                    else if (type === 'update')
                        _this.layer.applyEdits(null, features, null, function (add_results, update_results) {
                            obs.next(update_results);
                            if (!quiet)
                                vm.openSnackBar('Updated!', '');
                        }, function (e) {
                            vm.openSnackBar(e.toString() + ' ' + e.details[0], '');
                            obs.error(e);
                        });
                    // });
                });
            }
        });
    };
    ArcBaseService.prototype.openSnackBar = function (message, action) {
        var vm = this;
        vm.snackBar.open(message, action, {
            duration: 3000,
        });
    };
    ArcBaseService.prototype.prep_fields_meta = function (fields) {
        var fields_meta = {};
        this.layer.fields.forEach(function (field) {
            fields_meta[field.name] = field;
        });
        return fields_meta;
    };
    ArcBaseService.prototype.query = function () {
        var _this = this;
        var vm = this;
        return new Observable(function (observer) {
            _this.layerIsLoaded.subscribe(function () {
                loadModules(['esri/tasks/query'], _this.config.jsapi_config)
                    .then(function (_a) {
                    var query = _a[0];
                    var q = new query();
                    _this.loading = true;
                    _this.layer.queryFeatures(_this.filter, function (featureSet) {
                        // featureSet.features.forEach(function (feature, i) {
                        //   featureSet.features[i] =
                        // })
                        // featureSet.features.fields = this.prep_fields_meta();
                        featureSet.features = vm.convertFromEpoch(featureSet.features);
                        observer.next(featureSet.features);
                        vm.layer.queryCount(vm.filter, function (count) {
                            vm.count.next(count);
                        });
                        observer.complete();
                    }, function (e) {
                        vm.openSnackBar(e.toString() + ' ' + e.details[0], '');
                        observer.error(e);
                    });
                });
            });
        });
    };
    ArcBaseService.prototype.queryMax = function () {
        var _this = this;
        var vm = this;
        return new Observable(function (observer) {
            _this.layerIsLoaded.subscribe(function () {
                loadModules(['esri/tasks/query', 'esri/tasks/StatisticDefinition'], _this.config.jsapi_config)
                    .then(function (_a) {
                    var query = _a[0], statisticDefinition = _a[1];
                    var q = new query();
                    var statDef = new statisticDefinition();
                    _this.loading = true;
                    statDef.statisticType = 'max';
                    statDef.onStatisticField = 'ProjectNumber';
                    statDef.outStatisticFieldName = 'ProjectNumMAX';
                    q.returnGeometry = false;
                    q.where = '1=1';
                    q.outFields = ['*'];
                    q.outStatistics = [statDef];
                    _this.layer.queryFeatures(q, function (featureSet) {
                        observer.next(featureSet.features);
                        observer.complete();
                    }, function (e) {
                        vm.openSnackBar(e.toString() + ' ' + e.details[0], '');
                        observer.error(e);
                    });
                });
            });
        });
    };
    ArcBaseService.prototype.projectPoint = function (point, outSR) {
        var _this = this;
        return new Observable(function (observer) {
            loadModules(['esri/tasks/ProjectParameters', 'esri/tasks/GeometryService', 'esri/SpatialReference'], _this.config.jsapi_config)
                .then(function (_a) {
                var projectParameters = _a[0], geometryService = _a[1], sr = _a[2];
                outSR = outSR !== undefined ? outSR : new sr(4326);
                var params = new projectParameters();
                var geoService = new geometryService('https://tasks.arcgisonline.com/ArcGIS/rest/services/Geometry/GeometryServer');
                params.geometries = [point];
                params.outSR = outSR;
                geoService.project(params, function (projectedPoint) {
                    observer.next(projectedPoint);
                });
            });
        });
    };
    ArcBaseService.prototype.selectFeature = function (globalId, objectId, outFields) {
        var _this = this;
        if (outFields === void 0) { outFields = ['*']; }
        var vm = this;
        return new Observable(function (obs) {
            _this.layerIsLoaded.subscribe(function () {
                var q = new vm.Query();
                if (globalId)
                    q.where = "GlobalID='" + globalId + "'";
                else if (objectId)
                    q.objectIds = [objectId];
                q.outFields = outFields;
                _this.layer.clearSelection();
                if (globalId || objectId) {
                    _this.layer.selectFeatures(q, vm.FeatureLayer.SELECTION_NEW, function (features) {
                        features = vm.convertFromEpoch(features);
                        if (features[0].geometry !== null && features[0].geometry.rings.length === 0) {
                            // features[0].setGeometry(new vm.Polygon(new vm.SpatialReference(3857)));
                            features[0].setSymbol(vm.layer.renderer.getSymbol());
                        }
                        obs.next(features[0]);
                    }, function (e) {
                        vm.openSnackBar(e.toString() + ' ' + e.details[0], '');
                        obs.error(e);
                    });
                }
            });
        });
    };
    ArcBaseService.prototype.convertFromEpoch = function (features) {
        var vm = this;
        var keys = Object.keys(this.meta);
        features.map(function (feature) {
            for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                var key = keys_1[_i];
                if (vm.meta[key].type === 'esriFieldTypeDate' && feature.attributes[key] !== null) {
                    if (feature.attributes[key] === -2209132800000) {
                        feature.attributes[key] = null;
                    }
                    else
                        feature.attributes[key] = new Date(feature.attributes[key]);
                }
            }
        });
        return features;
    };
    ArcBaseService.prototype.prepForServer = function (features) {
        var vm = this;
        var keys = Object.keys(this.meta);
        features.map(function (feature) {
            for (var _i = 0, keys_2 = keys; _i < keys_2.length; _i++) {
                var key = keys_2[_i];
                if (vm.meta[key].type === 'esriFieldTypeDate' && feature.attributes[key] instanceof Date)
                    feature.attributes[key] = feature.attributes[key].getTime();
            }
            // if (feature.geometry.type === 'polygon' && feature.geometry.rings.length === 0) delete feature.geometry;
        });
        return features;
    };
    ArcBaseService.prototype.addFeature = function (feature, quiet) {
        if (quiet === void 0) { quiet = false; }
        return this.save(feature, 'add', quiet);
    };
    ArcBaseService.prototype.updateFeature = function (feature) {
        return this.save(feature, 'update');
    };
    ArcBaseService.prototype.deleteFeature = function (feature, quiet) {
        var _this = this;
        if (quiet === void 0) { quiet = false; }
        var vm = this;
        return new Observable(function (obs) {
            _this.layer.applyEdits(null, null, [feature], function (a, b, results) {
                if (!quiet)
                    vm.openSnackBar('Deleted!', '');
                obs.next(results);
            }, function (e) {
                vm.openSnackBar(e.toString() + ' ' + e.details[0], '');
                obs.error(e);
            });
        });
    };
    ArcBaseService.prototype.addClickListener = function (callback) {
        this.listener = this.layer.on('click', callback);
        this.listenerActive = true;
    };
    ArcBaseService.prototype.removeClickListener = function () {
        this.listener.remove();
    };
    Object.defineProperty(ArcBaseService.prototype, "data", {
        get: function () {
            return this.dataChange.value;
        },
        enumerable: true,
        configurable: true
    });
    ArcBaseService.prototype.getItems = function () {
        var _this = this;
        this.loadingService.show();
        return this.query().pipe(map(function (features) {
            _this.dataChange.next(features);
        }), finalize(function () { return _this.loadingService.hide(); }));
    };
    ArcBaseService.prototype.getPage = function (event) {
        // this.loadingService.setLoading(true);
        this.filter.start = event.pageIndex * event.pageSize;
        this.filter.num = event.pageSize;
        this.getItems()
            .subscribe();
    };
    ArcBaseService.prototype.getAttachments = function (objectId) {
        var _this = this;
        var vm = this;
        return new Observable(function (obs) {
            _this.layer.queryAttachmentInfos(objectId, function (attachments) {
                attachments.forEach(function (attachment) {
                    if (attachment.contentType.substring(0, 5) === 'image') {
                        attachment.previewUrl = attachment.url;
                    }
                    else {
                        attachment.previewUrl = 'assets/images/Very-Basic-File-icon.png';
                        attachment.extension = attachment.name.split('.').pop();
                    }
                });
                obs.next(attachments);
            });
        });
    };
    ArcBaseService.prototype.uploadAttachments = function (objectId, data) {
        var _this = this;
        var vm = this;
        return new Observable(function (obs) {
            _this.layer.addAttachment(objectId, data, function (result) {
                obs.next(result.attachmentId);
                vm.openSnackBar('Attachment Added!', '');
            }, function (e) {
                vm.openSnackBar(e.toString() + ' ' + e.details[0], '');
                obs.error(e);
            });
        });
    };
    ArcBaseService.prototype.deleteAttachments = function (objectId, attachmentId) {
        var _this = this;
        var vm = this;
        return new Observable(function (obs) {
            _this.layer.deleteAttachments(objectId, [attachmentId], function (response) {
                obs.next(response);
                vm.openSnackBar('Attachment Deleted!', '');
            }, function (e) {
                vm.openSnackBar(e.toString() + ' ' + e.details[0], '');
                obs.error(e);
            });
        });
    };
    return ArcBaseService;
}());
export { ArcBaseService };
var BaseDataSource = /** @class */ (function (_super) {
    __extends(BaseDataSource, _super);
    function BaseDataSource(_projectDatabase) {
        var _this = _super.call(this) || this;
        _this._projectDatabase = _projectDatabase;
        return _this;
    }
    BaseDataSource.prototype.connect = function () {
        return this._projectDatabase.dataChange;
    };
    BaseDataSource.prototype.disconnect = function () {
    };
    return BaseDataSource;
}(DataSource));
export { BaseDataSource };
