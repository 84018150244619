<!--<div class="editPane">-->
<mat-card *ngIf="project | async as p" class="main-card" style="display: flex; flex-direction: column;">


  <mat-grid-list cols="3"
                 rowHeight="1:1"
                 gutterSize="8px" >
    <mat-grid-tile *ngFor="let attachment of attachments "  style="background: #f5f5f5; overflow: hidden;">
      <button mat-button class="mat-fab mat-mini mat-accent" aria-label="Delete" (click)="showDeleteDialog(attachment, $event)"
              style="position: absolute; top: 0px; right: 0px; opacity: 0.7;" matTooltip="Delete File">
        <mat-icon class="material-icons">delete</mat-icon>
      </button>
      <a [href]="attachment.url" class="thumbnail" title="Download: {{attachment.name}}" target="_blank">
        <img class="mat-card-image" [src]="attachment.previewUrl"
             style="max-width: 500px;"/>

        <mat-grid-tile-footer style="background:rgba(176,190,197,0.7);"><h3>{{attachment.name}}</h3></mat-grid-tile-footer>
      </a>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="maxAttachments > attachments.length " style="background: #f5f5f5; overflow: hidden; cursor:pointer;" >
      <!-- input ng-disabled="uploading" type="file" name="attachment" id="attachmentInput"/ -->
      <a (click)="showUploadDialog($event)" class="thumbnail" title="Add attachment">
        <mat-icon class="material-icons" style="font-size:150px; position: absolute; top:15%; left:24%;">add</mat-icon>
        <mat-grid-tile-footer style="background:rgba(176,190,197,0.7);"><h3>Add attachment</h3></mat-grid-tile-footer>
      </a>
    </mat-grid-tile>
  </mat-grid-list>


</mat-card>
<!--</div>-->



