import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {EditPaneComponent} from './edit-pane/edit-pane.component';
import {MainMapComponent} from './main-map/main-map.component';
import {MapViewComponent} from './map-view/map-view.component';
import {RelatedTableFormComponent} from './related-table-form/related-table-form.component';
import {ProjectFormComponent} from './project-form/project-form.component';
import {ListViewComponent} from './list-view/list-view.component';
import {AssignedTaskComponent} from './assigned-task/assigned-task.component';
import {StipulationsComponent} from './stipulations/stipulations.component';
import {FieldworkComponent} from './fieldwork/fieldwork.component';
import {CulturalResourcesComponent} from './cultural-resources/cultural-resources.component';
import {CommentsComponent} from './comments/comments.component';
import {ReviewFormComponent} from './review-form/review-form.component';
import {ReportsComponent} from './reports/reports.component';
import {CommComponent} from './comm/comm.component';
import {AttachmentFormComponent} from './attachment-form/attachment-form.component';

const routes: Routes = [
  {
    path: '', component: MapViewComponent, children: [
      {
        path: 'edit/:id', component: EditPaneComponent, children: [
          {path: 'project', component: ProjectFormComponent},
          {path: 'stipulations', component: StipulationsComponent},
          {path: 'assigned', component: AssignedTaskComponent},
          {path: 'fieldwork', component: FieldworkComponent},
          {path: 'cultural resources', component: CulturalResourcesComponent},
          {path: 'review', component: ReviewFormComponent},
          {path: 'comments', component: CommentsComponent},
          {path: 'communications', component: CommComponent},
          {path: 'attachments', component: AttachmentFormComponent},
          {path: '', redirectTo: 'project', pathMatch: 'full'},
        ]
      },
      {path: 'projects', component: ListViewComponent},
      {path: '', redirectTo: 'projects', pathMatch: 'full'}
    ]
  },
  {path: 'reports', component: ReportsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
