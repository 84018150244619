import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoadingService} from './loading.service';
import {loadModules} from 'esri-loader';
import {ConfigService} from './config.service';
import {Observable} from 'rxjs';
import {ArcBaseService} from './arc-base.service';

@Injectable()
export class ShpoService extends ArcBaseService {

  constructor(snackBar: MatSnackBar, loadingService: LoadingService) {
    super ('7', snackBar, loadingService);
  }
  create_new_shpo(project_fk) {
    const vm = this;
    // const project = new Project();
    const config = new ConfigService();
    return new Observable<any>(obs => {
      vm.layerIsLoaded.subscribe(() => {
        loadModules(['esri/graphic', 'esri/geometry/Polygon', 'esri/SpatialReference'], config.jsapi_config).then(([Graphic, Polygon, SpatialReference]) => {
          const project = new Graphic(vm.layer.templates[0].prototype.toJson());
          // project.setSymbol(vm.layer.renderer.getSymbol());
          // project.setGeometry(new Polygon(new SpatialReference(3857)));
          project.attributes.GlobalID = 'new';
          project.attributes.ProjectFK = project_fk;
          obs.next(project);
          obs.complete();
        });
      });
    });
  }

}
