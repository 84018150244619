import {Component, OnInit} from '@angular/core';
import {loadModules} from 'esri-loader';
import {
  trigger,
  state,

  style,
  animate,
  transition,
  query,
} from '@angular/animations';
import {ConfigService} from '../services/config.service';
import {MainMapService} from '../services/main-map.service';
import {ProjectService} from '../services/project.service';
import {Router} from '@angular/router';
import {IdentityManagementService} from '../services/identity-management.service';


@Component({
  selector: 'app-main-map',
  templateUrl: './main-map.component.html',
  styleUrls: ['./main-map.component.css'],
  animations: [
  trigger('spinInOut', [
    state('in', style({transform: 'rotate(0)', opacity: '1'})),
    transition(':enter', [
      style({transform: 'rotate(-180deg)', opacity: '0'}),
      animate('150ms ease')
    ]),
    transition(':leave', [
      animate('150ms ease', style({transform: 'rotate(180deg)', opacity: '0'}))
    ]),
  ]),
  trigger('preventInitialAnimation', [
    transition(':enter', [
      query(':enter', [], {optional: true})
    ]),
  ]),
],
})
export class MainMapComponent implements OnInit {


  constructor(private config: ConfigService, public mainMapService: MainMapService,
              public projectService: ProjectService, public route: Router, private identityManager: IdentityManagementService) {
  }

  basemaps = [
    {name: '1'},
    {name: '2'},
    {name: '3'},
  ];
  ngOnInit() {
    this.mainMapService.init('mainMap').subscribe(map => {
      map.addLayer(this.projectService.layer);
      const vm = this;
      this.projectService.addClickListener(function (e) {
        vm.route.navigate(['/edit', e.graphic.attributes.GlobalID]);
      });
    });

  }
}
