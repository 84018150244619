import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {TaskService} from '../services/task.service';
import {ProjectService} from '../services/project.service';
import {Subject} from 'rxjs';
import {LoadingService} from '../services/loading.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-task-pop-up',
  templateUrl: './task-pop-up.component.html',
  styleUrls: ['./task-pop-up.component.css']
})
export class TaskPopUpComponent implements OnInit{
  taskForm = new FormGroup({
    Project_Number: new FormControl(),
    Task: new FormControl(),
    Status: new FormControl(),
    Cost: new FormControl(),
    Description: new FormControl(),
    Start_Date: new FormControl(),
    Due_Date: new FormControl(),
    Attachments: new FormControl(),
    GlobalID: new FormControl(),
    ProjectFK: new FormControl(),
    OBJECTID: new FormControl(),
    Assigned_To: new FormControl()
  });
  constructor(public dialogRef: MatDialogRef<TaskPopUpComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public taskService: TaskService, public projectService: ProjectService, public  loadingService: LoadingService) {}
ngOnInit() {
    // this.loadingService.show();
    this.taskForm.patchValue(this.data.task.attributes);
}
  onNoClick(): void {
    this.dialogRef.close();
  }
  save() {
    this.data.task.attributes = this.taskForm.value;
    if (this.data.task.attributes.GlobalID === 'new') this.taskService.addFeature(this.data.task).subscribe(() => this.dialogRef.close());
    else this.taskService.updateFeature(this.data.task).subscribe(() => this.dialogRef.close());
  }
}

