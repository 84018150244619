import {Component, Inject, OnInit} from '@angular/core';
import {CulturalPopUpComponent} from '../cultural-pop-up/cultural-pop-up.component';
import {LoadingService} from '../services/loading.service';
import {ProjectService} from '../services/project.service';
import {TcpService} from '../services/tcp.service';
import {ShpoService} from '../services/shpo.service';
import {Subject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-shpo-pop-up',
  templateUrl: './shpo-pop-up.component.html',
  styleUrls: ['./shpo-pop-up.component.css']
})
export class ShpoPopUpComponent implements OnInit {
  project: Subject<any>;
  featureForm = new FormGroup({
    SHPO_Report: new FormControl(),
    Report_Date: new FormControl(),
    Author: new FormControl(),
    Investigation_Acres: new FormControl(),
    Acres_Surveyed: new FormControl(),
    Findings: new FormControl(),
    LevelofEffort: new FormControl(),
    ProjectFK: new FormControl(),
    GlobalID: new FormControl(),
    ObjectID: new FormControl()
  });
  constructor(public dialogRef: MatDialogRef<ShpoPopUpComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public shpoService: ShpoService, public projectService: ProjectService, public  loadingService: LoadingService) {}


  ngOnInit() {
    this.featureForm.patchValue(this.data.shpo.attributes);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
    save() {
      this.data.shpo.attributes = this.featureForm.value;
      if (this.data.shpo.attributes.GlobalID === 'new') this.shpoService.addFeature(this.data.shpo).subscribe(() => this.dialogRef.close());
      else this.shpoService.updateFeature(this.data.shpo).subscribe(() => this.dialogRef.close());
    }
}
