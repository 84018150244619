<mat-card class="main-card" style="display: flex; flex-direction: column; flex: 1">
  <mat-table *ngIf="tcpService.layerIsLoaded | async" #table [dataSource]="tcpService.datasource" style="max-height: 100%; flex:1 1; overflow:auto;">
    <ng-container matColumnDef="TCP_in_APE">
      <mat-header-cell *matHeaderCellDef>{{tcpService.meta.TCP_in_APE.alias}}</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.attributes.TCP_in_APE}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
    <mat-row style="cursor: pointer;" *matRowDef="let element; columns: displayColumns" (click)="openDialog(element)" ></mat-row>
  </mat-table>
  <button mat-mini-fab *ngIf="project_id | async as p_id" [routerLink]="['new']" (click)="openDialog({attributes: {'GlobalID': 'new', 'ProjectFK': p_id }})" ><mat-icon>add</mat-icon></button>
</mat-card>
