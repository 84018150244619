var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ArcBaseService } from './arc-base.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from './loading.service';
import { Observable } from 'rxjs';
import { loadModules } from 'esri-loader';
import { ConfigService } from './config.service';
var SitesInApeService = /** @class */ (function (_super) {
    __extends(SitesInApeService, _super);
    function SitesInApeService(snackBar, loadingService) {
        return _super.call(this, '8', snackBar, loadingService) || this;
    }
    SitesInApeService.prototype.create_new_site_APE = function (project_fk) {
        var vm = this;
        // const project = new Project();
        var config = new ConfigService();
        return new Observable(function (obs) {
            vm.layerIsLoaded.subscribe(function () {
                loadModules(['esri/graphic', 'esri/geometry/Polygon', 'esri/SpatialReference'], config.jsapi_config).then(function (_a) {
                    var Graphic = _a[0], Polygon = _a[1], SpatialReference = _a[2];
                    var project = new Graphic(vm.layer.templates[0].prototype.toJson());
                    // project.setSymbol(vm.layer.renderer.getSymbol());
                    // project.setGeometry(new Polygon(new SpatialReference(3857)));
                    project.attributes.GlobalID = 'new';
                    project.attributes.ProjectFK = project_fk;
                    obs.next(project);
                    obs.complete();
                });
            });
        });
    };
    return SitesInApeService;
}(ArcBaseService));
export { SitesInApeService };
