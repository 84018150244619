import { Injectable } from '@angular/core';
import {ArcBaseService} from './arc-base.service';
import {LoadingService} from './loading.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfigService} from './config.service';
import {Observable} from 'rxjs';
import {loadModules} from 'esri-loader';

export class ReviewResponseLU {
  attributes: {
    projects_fk: number | string;
    Review_Response: string;
    GlobalID: number | string;
  };
}

@Injectable()
export class ReviewResponseLuService extends ArcBaseService {
  constructor(snackBar: MatSnackBar, loadingService: LoadingService) {
    super('12', snackBar, loadingService);
  }
  create_new_review_response(project_fk, response) {
    const vm = this;
    const config = new ConfigService();
    return new Observable<any>(obs => {
      vm.layerIsLoaded.subscribe(() => {
        loadModules(['esri/graphic', 'esri/geometry/Polygon', 'esri/SpatialReference'], config.jsapi_config).then(([Graphic, Polygon, SpatialReference]) => {
          const reviewResponse = new Graphic(vm.layer.templates[0].prototype.toJson());
          reviewResponse.attributes.GlobalID = 'new';
          reviewResponse.attributes.projects_fk = project_fk;
          reviewResponse.attributes.Review_Response = response;
          vm.addFeature(reviewResponse, true).subscribe();
          obs.next(reviewResponse);
          obs.complete();
        });
      });
    });
  }
}
